export const dataNDVI = {
  id: 'd3e1a708-0913-4b3a-943d-4c6c7f35f056',
  crop_end: '2021-10-18',
  crop_start: '2021-10-15',
  planting_end: '2021-06-10',
  planting_start: '2021-06-07',
  glebes: [
    {
      last_stage_code: 4,
      last_stage_description: 'Colhido',
      last_stage_screen: '(4) Colhido',
      id: 4312527,
      financed_area: '7,90',
      index: 1,
      area: '7,90',
      planting_area: '7,68',
      geodetic_coordinate: 'Datum WGS-84 ESPG:4326',
      coordinates_area: [
        {
          lat: -25.209682,
          lng: -53.292528,
        },
        {
          lat: -25.207546,
          lng: -53.292378,
        },
        {
          lat: -25.206673,
          lng: -53.291648,
        },
        {
          lat: -25.205275,
          lng: -53.291305,
        },
        {
          lat: -25.204634,
          lng: -53.291348,
        },
        {
          lat: -25.203994,
          lng: -53.291648,
        },
        {
          lat: -25.203722,
          lng: -53.291219,
        },
        {
          lat: -25.203411,
          lng: -53.290425,
        },
        {
          lat: -25.202596,
          lng: -53.289824,
        },
        {
          lat: -25.202887,
          lng: -53.291026,
        },
        {
          lat: -25.202887,
          lng: -53.291755,
        },
        {
          lat: -25.202984,
          lng: -53.29227,
        },
        {
          lat: -25.203353,
          lng: -53.292356,
        },
        {
          lat: -25.204479,
          lng: -53.292099,
        },
        {
          lat: -25.205508,
          lng: -53.292077,
        },
        {
          lat: -25.206129,
          lng: -53.292442,
        },
        {
          lat: -25.20677,
          lng: -53.293086,
        },
        {
          lat: -25.207294,
          lng: -53.293644,
        },
        {
          lat: -25.208362,
          lng: -53.293322,
        },
        {
          lat: -25.209449,
          lng: -53.293193,
        },
      ],
      geom: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [-53.292528, -25.209682],
              [-53.292378, -25.207546],
              [-53.291648, -25.206673],
              [-53.291305, -25.205275],
              [-53.291348, -25.204634],
              [-53.291648, -25.203994],
              [-53.291219, -25.203722],
              [-53.290425, -25.203411],
              [-53.289824, -25.202596],
              [-53.291026, -25.202887],
              [-53.291755, -25.202887],
              [-53.29227, -25.202984],
              [-53.292356, -25.203353],
              [-53.292099, -25.204479],
              [-53.292077, -25.205508],
              [-53.292442, -25.206129],
              [-53.293086, -25.20677],
              [-53.293644, -25.207294],
              [-53.293322, -25.208362],
              [-53.293193, -25.209449],
            ],
          ],
        },
        properties: {
          crs: {
            type: 'name',
            properties: {
              name: 'EPSG:4326',
            },
          },
          type: 'Polygon',
          name: 'geom-494954-4312527-1',
          area: '7,9 ha',
        },
      },
      geom_planting: {
        id: '0',
        type: 'Feature',
        properties: {
          area_ha: 7.677425571987791,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [-53.290061801077506, -25.20267786421343],
              [-53.290056331079036, -25.202858100912447],
              [-53.29015538422167, -25.202860589188283],
              [-53.290152649275306, -25.202950707545853],
              [-53.29025170250372, -25.20295319576569],
              [-53.29024623271671, -25.203133432496923],
              [-53.29034528610352, -25.203135920670864],
              [-53.29034255126289, -25.203226039044537],
              [-53.290441604735484, -25.203228527162473],
              [-53.29043613515994, -25.203408763925907],
              [-53.29053518879093, -25.203411251997988],
              [-53.29053390201097, -25.203453655573565],
              [-53.291219, -25.203722],
              [-53.291648, -25.20399399999999],
              [-53.29161255339129, -25.204069619431912],
              [-53.2917046960688, -25.20407193325479],
              [-53.29169922814438, -25.20425217025645],
              [-53.29160017370186, -25.204249682886772],
              [-53.29159470557954, -25.204429919862704],
              [-53.29149565100494, -25.20442743240669],
              [-53.29148744850488, -25.204697787829932],
              [-53.291388393725576, -25.204695300277457],
              [-53.291363562552064, -25.205513689352102],
              [-53.29140628262544, -25.205687807901914],
              [-53.2914573716578, -25.205689090942567],
              [-53.29145178359535, -25.205873260834693],
              [-53.291517605840035, -25.206141539254688],
              [-53.291542755791596, -25.206142170859643],
              [-53.291540004923434, -25.206232833477994],
              [-53.291648, -25.206672999999995],
              [-53.292378, -25.207546000000004],
              [-53.292528, -25.209682000000004],
              [-53.293193, -25.209449],
              [-53.293322, -25.208362000000005],
              [-53.293644, -25.207294000000005],
              [-53.293625588051704, -25.20727670992669],
              [-53.293590139337724, -25.20727582017073],
              [-53.29359109466799, -25.207244318290375],
              [-53.29352691144213, -25.207184045870388],
              [-53.2934938152585, -25.20718321514305],
              [-53.29349470720729, -25.207153803900745],
              [-53.29342823477379, -25.207091381758897],
              [-53.29339749132451, -25.20709061006808],
              [-53.293398319883714, -25.20706328963991],
              [-53.293329558046686, -25.20699871759224],
              [-53.29330116753581, -25.206998004945802],
              [-53.29330193269731, -25.206972775507875],
              [-53.293230881260854, -25.206906053370414],
              [-53.293204843892354, -25.206905399776264],
              [-53.2932055456464, -25.206882261503072],
              [-53.293086, -25.206770000000002],
              [-53.29244200000001, -25.206128999999997],
              [-53.292077, -25.205507999999988],
              [-53.29209900000001, -25.204479000000003],
              [-53.292356, -25.203353],
              [-53.29227466076815, -25.203003997947032],
              [-53.29223276983653, -25.203002946277955],
              [-53.29223355272321, -25.202977135173104],
              [-53.291839307516625, -25.202902879279826],
              [-53.29120691585256, -25.202886999999997],
              [-53.291026, -25.202886999999997],
              [-53.28996412752324, -25.202629924383746],
              [-53.28996274809326, -25.20267537589173],
              [-53.290061801077506, -25.20267786421343],
            ],
          ],
        },
      },
      images: [
        {
          url: 'https://spoteye-files-stg.s3.amazonaws.com/monitoring/99ed405c-e604-4971-ab4f-44d45962d85f/COPERNICUS/S2_SR_HARMONIZED/20210724T134209_20210724T134212_T21JZN.png?AWSAccessKeyId=AKIAY6YG42GLOAIH2ZSO&Expires=1719591644&Signature=aZZTOz6UjVZ4Oca8LU3UOg5f5JY%3D',
          text: 'Pós-plantio',
          id: 'COPERNICUS/S2_SR_HARMONIZED/20210724T134209_20210724T134212_T21JZN',
          date: '2021-07-24',
          filename:
            'monitoring/99ed405c-e604-4971-ab4f-44d45962d85f/COPERNICUS/S2_SR_HARMONIZED/20210724T134209_20210724T134212_T21JZN.png',
        },
        {
          url: 'https://spoteye-files-stg.s3.amazonaws.com/monitoring/99ed405c-e604-4971-ab4f-44d45962d85f/COPERNICUS/S2_SR_HARMONIZED/20210818T134211_20210818T134358_T22JBT.png?AWSAccessKeyId=AKIAY6YG42GLOAIH2ZSO&Expires=1719591644&Signature=ExE5vy2T2l8jw6s2T7lzO21H%2FBQ%3D',
          text: 'Vigor Pleno',
          id: 'COPERNICUS/S2_SR_HARMONIZED/20210818T134211_20210818T134358_T22JBT',
          date: '2021-08-18',
          filename:
            'monitoring/99ed405c-e604-4971-ab4f-44d45962d85f/COPERNICUS/S2_SR_HARMONIZED/20210818T134211_20210818T134358_T22JBT.png',
        },
        {
          url: 'https://spoteye-files-stg.s3.amazonaws.com/monitoring/99ed405c-e604-4971-ab4f-44d45962d85f/COPERNICUS/S2_SR_HARMONIZED/20210927T134031_20210927T134030_T22JBT.png?AWSAccessKeyId=AKIAY6YG42GLOAIH2ZSO&Expires=1719591644&Signature=gT66PRIaB0hyFfuVE%2FT6JABWzog%3D',
          text: 'Pré-colheita',
          id: 'COPERNICUS/S2_SR_HARMONIZED/20210927T134031_20210927T134030_T22JBT',
          date: '2021-09-27',
          filename:
            'monitoring/99ed405c-e604-4971-ab4f-44d45962d85f/COPERNICUS/S2_SR_HARMONIZED/20210927T134031_20210927T134030_T22JBT.png',
        },
      ],
      compliance: {
        property: {
          id: '97296dc3-9cc9-403f-b69e-c54fe26217f1',
          external_code: '299a2dae-fb75-4e14-a978-1cf88696e868',
          description: null,
          size_m2: '79351.87219115993',
          size_ha: '7.935187219115993',
          created_at: '2024-06-18T15:30:22.847Z',
          geojson: {
            id: '97296dc3-9cc9-403f-b69e-c54fe26217f1',
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [-53.292528, -25.209682],
                  [-53.292378, -25.207546],
                  [-53.291648, -25.206673],
                  [-53.291305, -25.205275],
                  [-53.291348, -25.204634],
                  [-53.291648, -25.203994],
                  [-53.291219, -25.203722],
                  [-53.290425, -25.203411],
                  [-53.289824, -25.202596],
                  [-53.291026, -25.202887],
                  [-53.291755, -25.202887],
                  [-53.29227, -25.202984],
                  [-53.292356, -25.203353],
                  [-53.292099, -25.204479],
                  [-53.292077, -25.205508],
                  [-53.292442, -25.206129],
                  [-53.293086, -25.20677],
                  [-53.293644, -25.207294],
                  [-53.293322, -25.208362],
                  [-53.293193, -25.209449],
                  [-53.292528, -25.209682],
                ],
              ],
            },
            properties: {
              status: 'PENDING',
              size_ha: 7.935187219115993,
              description: null,
            },
          },
        },
        summary: [
          {
            type_area: 'preservation',
            name: 'Área de preservação ambiental',
            color: '#2a6318',
            intersection_area_ha: '0.353',
            intersection_area_percentage: '4.471',
          },
        ],
        details: [
          {
            id: 'e7871821-27f1-4871-a0f1-0e34424c58d1',
            property_analysis_id: '97296dc3-9cc9-403f-b69e-c54fe26217f1',
            type_area: 'preservation',
            geojson: {
              id: '18465147-b77e-4840-b905-88d3c5e43c09',
              type: 'Feature',
              geometry: {
                type: 'MultiPolygon',
                coordinates: [
                  [
                    [
                      [-53.298143396, -25.207947558],
                      [-53.29740098, -25.206841945],
                      [-53.297363023, -25.20684622],
                      [-53.297354412, -25.206848058],
                      [-53.297304076, -25.206864099],
                      [-53.297295989, -25.206867582],
                      [-53.29724975, -25.206893135],
                      [-53.297242498, -25.206898129],
                      [-53.297202132, -25.206932212],
                      [-53.297195994, -25.206938524],
                      [-53.297163053, -25.206979827],
                      [-53.297158264, -25.206987216],
                      [-53.297134014, -25.207034152],
                      [-53.297130758, -25.207042333],
                      [-53.297116131, -25.207093098],
                      [-53.297114534, -25.207101757],
                      [-53.297110092, -25.2071544],
                      [-53.297110215, -25.207163204],
                      [-53.297116128, -25.207215702],
                      [-53.297117966, -25.207224313],
                      [-53.297134007, -25.207274649],
                      [-53.29713749, -25.207282736],
                      [-53.297149131, -25.207306483],
                      [-53.297385165, -25.207733598],
                      [-53.297399077, -25.20775609],
                      [-53.297404071, -25.207763342],
                      [-53.297436705, -25.207801992],
                      [-53.297545844, -25.208041802],
                      [-53.297557967, -25.208065307],
                      [-53.297562382, -25.208072925],
                      [-53.297593226, -25.208115816],
                      [-53.297599043, -25.208122427],
                      [-53.297637662, -25.208158477],
                      [-53.297644656, -25.208163825],
                      [-53.297689566, -25.208191648],
                      [-53.297697469, -25.208195529],
                      [-53.297746944, -25.208214056],
                      [-53.297755453, -25.208216321],
                      [-53.297807592, -25.20822484],
                      [-53.297816379, -25.208225401],
                      [-53.297869178, -25.208223585],
                      [-53.297877906, -25.208222421],
                      [-53.297929336, -25.208210339],
                      [-53.297937669, -25.208207494],
                      [-53.297985753, -25.208185611],
                      [-53.297993371, -25.208181196],
                      [-53.298036263, -25.208150352],
                      [-53.298042873, -25.208144535],
                      [-53.298078923, -25.208105916],
                      [-53.298084272, -25.208098922],
                      [-53.298112095, -25.208054012],
                      [-53.298115976, -25.208046108],
                      [-53.298134503, -25.207996633],
                      [-53.298136768, -25.207988125],
                      [-53.298143396, -25.207947558],
                    ],
                    [
                      [-53.297791131, -25.207930171],
                      [-53.297652486, -25.207625526],
                      [-53.297647915, -25.207624683],
                      [-53.297639734, -25.207621428],
                      [-53.297632345, -25.207616639],
                      [-53.297626032, -25.2076105],
                      [-53.297621039, -25.207603248],
                      [-53.297385004, -25.207176134],
                      [-53.297381521, -25.207168047],
                      [-53.297379683, -25.207159436],
                      [-53.29737956, -25.207150632],
                      [-53.297381157, -25.207141972],
                      [-53.297384412, -25.207133791],
                      [-53.297389201, -25.207126403],
                      [-53.297395339, -25.20712009],
                      [-53.297402591, -25.207115096],
                      [-53.297410678, -25.207111613],
                      [-53.297419289, -25.207109775],
                      [-53.297428093, -25.207109652],
                      [-53.297436753, -25.207111249],
                      [-53.297444934, -25.207114504],
                      [-53.297452322, -25.207119293],
                      [-53.297458635, -25.207125432],
                      [-53.297463629, -25.207132684],
                      [-53.297687103, -25.207537071],
                      [-53.297689051, -25.207537195],
                      [-53.29769756, -25.20753946],
                      [-53.297705463, -25.207543341],
                      [-53.297712458, -25.20754869],
                      [-53.297718274, -25.2075553],
                      [-53.29772269, -25.207562918],
                      [-53.297872893, -25.20789296],
                      [-53.297875738, -25.207901293],
                      [-53.297876901, -25.207910021],
                      [-53.29787634, -25.207918808],
                      [-53.297874075, -25.207927317],
                      [-53.297870194, -25.207935221],
                      [-53.297864846, -25.207942215],
                      [-53.297858235, -25.207948032],
                      [-53.297850617, -25.207952447],
                      [-53.297842284, -25.207955291],
                      [-53.297833557, -25.207956455],
                      [-53.297824769, -25.207955894],
                      [-53.297816261, -25.207953629],
                      [-53.297808357, -25.207949748],
                      [-53.297801363, -25.207944399],
                      [-53.297795546, -25.207937789],
                      [-53.297791131, -25.207930171],
                    ],
                  ],
                  [
                    [
                      [-53.297458705, -25.210024143],
                      [-53.297728367, -25.210022075],
                      [-53.297765657, -25.208706274],
                      [-53.297766768, -25.208697539],
                      [-53.297769561, -25.208689189],
                      [-53.29777393, -25.208681544],
                      [-53.297779707, -25.208674899],
                      [-53.297786669, -25.208669508],
                      [-53.297794548, -25.208665579],
                      [-53.297803043, -25.208663263],
                      [-53.297811827, -25.208662648],
                      [-53.297820562, -25.208663759],
                      [-53.297828912, -25.208666553],
                      [-53.297836556, -25.208670922],
                      [-53.297843202, -25.208676698],
                      [-53.297848593, -25.20868366],
                      [-53.297852522, -25.20869154],
                      [-53.297854838, -25.208700035],
                      [-53.297855452, -25.208708818],
                      [-53.297818254, -25.210021386],
                      [-53.298087915, -25.210019319],
                      [-53.298124839, -25.208716453],
                      [-53.29812429, -25.208690012],
                      [-53.298123675, -25.208681228],
                      [-53.298114841, -25.208629142],
                      [-53.298112524, -25.208620647],
                      [-53.298093698, -25.208571285],
                      [-53.298089769, -25.208563405],
                      [-53.298061674, -25.208518665],
                      [-53.298056283, -25.208511703],
                      [-53.29802, -25.208473303],
                      [-53.298013355, -25.208467527],
                      [-53.297970277, -25.208436943],
                      [-53.297962633, -25.208432574],
                      [-53.297914416, -25.208410982],
                      [-53.297906066, -25.208408189],
                      [-53.297854564, -25.208396418],
                      [-53.297845829, -25.208395307],
                      [-53.29779302, -25.208393811],
                      [-53.297784237, -25.208394425],
                      [-53.29773215, -25.20840326],
                      [-53.297723655, -25.208405576],
                      [-53.297674294, -25.208424403],
                      [-53.297666414, -25.208428332],
                      [-53.297621673, -25.208456426],
                      [-53.297614711, -25.208461817],
                      [-53.297576312, -25.2084981],
                      [-53.297570535, -25.208504746],
                      [-53.297539952, -25.208547823],
                      [-53.297535583, -25.208555468],
                      [-53.297513991, -25.208603684],
                      [-53.297511197, -25.208612034],
                      [-53.297499427, -25.208663537],
                      [-53.297498316, -25.208672271],
                      [-53.29749627, -25.208698639],
                      [-53.297458705, -25.210024143],
                    ],
                  ],
                  [
                    [
                      [-53.28917887, -25.208692248],
                      [-53.289354753, -25.208692854],
                      [-53.289364988, -25.209014023],
                      [-53.289699386, -25.207879453],
                      [-53.289699465, -25.207879188],
                      [-53.289956957, -25.20702496],
                      [-53.289956981, -25.207024882],
                      [-53.290557796, -25.205044601],
                      [-53.290559696, -25.20503948],
                      [-53.290810997, -25.204471043],
                      [-53.290645766, -25.20379829],
                      [-53.289753799, -25.202837512],
                      [-53.289283181, -25.202411693],
                      [-53.289277262, -25.202405174],
                      [-53.289272729, -25.202397625],
                      [-53.289269756, -25.202389337],
                      [-53.289268457, -25.202380629],
                      [-53.289268881, -25.202371834],
                      [-53.289271013, -25.202363291],
                      [-53.289274771, -25.202355328],
                      [-53.28928001, -25.202348252],
                      [-53.28928653, -25.202342333],
                      [-53.289294078, -25.2023378],
                      [-53.289302366, -25.202334827],
                      [-53.289311074, -25.202333527],
                      [-53.289319869, -25.202333952],
                      [-53.289328412, -25.202336084],
                      [-53.289336375, -25.202339842],
                      [-53.289343452, -25.202345081],
                      [-53.289815521, -25.202772213],
                      [-53.289818302, -25.202774959],
                      [-53.290719524, -25.203745707],
                      [-53.290724199, -25.203751684],
                      [-53.290727801, -25.203758363],
                      [-53.290730227, -25.203765553],
                      [-53.290901888, -25.204464487],
                      [-53.290903174, -25.204474221],
                      [-53.290902314, -25.204484001],
                      [-53.290899349, -25.204493361],
                      [-53.290642963, -25.2050733],
                      [-53.290042955, -25.207050924],
                      [-53.289785514, -25.207904982],
                      [-53.289445659, -25.209058064],
                      [-53.289697559, -25.210083651],
                      [-53.289969003, -25.21008157],
                      [-53.289968267, -25.210065035],
                      [-53.289967015, -25.21005632],
                      [-53.289961974, -25.210030358],
                      [-53.289724733, -25.209064452],
                      [-53.29004378, -25.207981966],
                      [-53.290300912, -25.207128934],
                      [-53.290896083, -25.205167253],
                      [-53.291145831, -25.204602329],
                      [-53.291156264, -25.204574736],
                      [-53.291159229, -25.204565376],
                      [-53.291170772, -25.204507608],
                      [-53.291171632, -25.204497828],
                      [-53.291170348, -25.204438932],
                      [-53.291169062, -25.204429198],
                      [-53.291163605, -25.204400208],
                      [-53.290991943, -25.203701274],
                      [-53.290985578, -25.203679396],
                      [-53.290983152, -25.203672206],
                      [-53.290965004, -25.20363045],
                      [-53.290961403, -25.203623771],
                      [-53.290936485, -25.203585666],
                      [-53.29093181, -25.203579688],
                      [-53.290917027, -25.203562349],
                      [-53.290015805, -25.202591601],
                      [-53.290007639, -25.202583181],
                      [-53.290004858, -25.202580435],
                      [-53.289996333, -25.202572377],
                      [-53.289524264, -25.202145246],
                      [-53.289503806, -25.202128485],
                      [-53.28949673, -25.202123246],
                      [-53.289451393, -25.202096124],
                      [-53.28944343, -25.202092367],
                      [-53.289393673, -25.202074611],
                      [-53.28938513, -25.202072478],
                      [-53.289332865, -25.202064771],
                      [-53.28932407, -25.202064346],
                      [-53.289271306, -25.202066983],
                      [-53.289262597, -25.202068282],
                      [-53.289211361, -25.202081162],
                      [-53.289203074, -25.202084136],
                      [-53.289155335, -25.202106764],
                      [-53.289147786, -25.202111297],
                      [-53.289105379, -25.202142804],
                      [-53.28909886, -25.202148722],
                      [-53.289063415, -25.202187897],
                      [-53.289058176, -25.202194974],
                      [-53.289031054, -25.202240311],
                      [-53.289027296, -25.202248273],
                      [-53.28900954, -25.20229803],
                      [-53.289007408, -25.202306573],
                      [-53.2889997, -25.202358838],
                      [-53.288999276, -25.202367633],
                      [-53.289001912, -25.202420397],
                      [-53.289003212, -25.202429106],
                      [-53.289016092, -25.202480342],
                      [-53.289019065, -25.20248863],
                      [-53.289041693, -25.202536369],
                      [-53.289046226, -25.202543917],
                      [-53.289077733, -25.202586324],
                      [-53.289083652, -25.202592843],
                      [-53.289102368, -25.202611528],
                      [-53.289564283, -25.203029472],
                      [-53.290400717, -25.203930434],
                      [-53.290527368, -25.204446102],
                      [-53.290313214, -25.204930513],
                      [-53.290307045, -25.204945702],
                      [-53.290305144, -25.204950823],
                      [-53.290299909, -25.204966359],
                      [-53.289699094, -25.20694664],
                      [-53.289699001, -25.206946949],
                      [-53.289441438, -25.207801411],
                      [-53.2894412, -25.207802204],
                      [-53.289441121, -25.207802469],
                      [-53.289440886, -25.207803264],
                      [-53.28917887, -25.208692248],
                    ],
                  ],
                  [
                    [
                      [-53.289368364, -25.209119975],
                      [-53.289399147, -25.210085939],
                      [-53.289605232, -25.210084359],
                      [-53.289368364, -25.209119975],
                    ],
                  ],
                  [
                    [
                      [-53.289675505, -25.195652677],
                      [-53.289520319, -25.195431239],
                      [-53.289516346, -25.195432558],
                      [-53.288658039, -25.19562672],
                      [-53.288650131, -25.195627782],
                      [-53.288642161, -25.195627429],
                      [-53.288062804, -25.195549764],
                      [-53.28805731, -25.195548675],
                      [-53.287542326, -25.195412762],
                      [-53.287538939, -25.195411724],
                      [-53.286873751, -25.195178729],
                      [-53.286865767, -25.195175017],
                      [-53.286858659, -25.19516982],
                      [-53.286854272, -25.195165043],
                      [-53.286621683, -25.195302114],
                      [-53.286622483, -25.195303428],
                      [-53.286654237, -25.19534565],
                      [-53.286660194, -25.195352134],
                      [-53.286699575, -25.19538735],
                      [-53.286706682, -25.195392548],
                      [-53.286752177, -25.195419404],
                      [-53.286760162, -25.195423115],
                      [-53.286784663, -25.195433072],
                      [-53.28744985, -25.195666067],
                      [-53.287459949, -25.195669382],
                      [-53.287463335, -25.19567042],
                      [-53.287473556, -25.195673334],
                      [-53.28798854, -25.195809248],
                      [-53.28800492, -25.195813028],
                      [-53.288010414, -25.195814117],
                      [-53.288026997, -25.195816869],
                      [-53.288606354, -25.195894534],
                      [-53.288630219, -25.195896659],
                      [-53.28863819, -25.195897012],
                      [-53.288686012, -25.195894878],
                      [-53.28869392, -25.195893815],
                      [-53.2887175, -25.195889573],
                      [-53.289575807, -25.195695411],
                      [-53.289601285, -25.195688317],
                      [-53.289609641, -25.195685542],
                      [-53.289657905, -25.195664057],
                      [-53.289665559, -25.195659705],
                      [-53.289675505, -25.195652677],
                    ],
                  ],
                ],
              },
              properties: {
                id: '18465147-b77e-4840-b905-88d3c5e43c09',
                name: 'Área de preservação ambiental',
                year: 2024,
                color: '#2a6318',
                type_area: 'preservation',
                description:
                  'Área de Preservação Permanente de Rios até 10 metros',
              },
            },
            geojson_difference: {
              id: 'd242cdaf-670a-453a-b9bc-fdda2daae3d1',
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [-53.289824, -25.202596],
                    [-53.290425, -25.203411],
                    [-53.290959057, -25.203620183],
                    [-53.290936485, -25.203585666],
                    [-53.29093181, -25.203579688],
                    [-53.290917027, -25.203562349],
                    [-53.29007668, -25.202657173],
                    [-53.289824, -25.202596],
                  ],
                ],
              },
              properties: {
                id: 'fe26f100-2221-4dce-9d17-b4a54597005a',
                name: 'Área sobreposta',
                type: 'intersection',
                color: '#ec0b0ba1',
                created_at: '2022-10-28T12:05:33.350959',
                updated_at: null,
                description:
                  'Área retirada da sobreposição na verificação de compliance',
              },
            },
          },
          {
            id: '768f19dc-52ed-4d54-a621-15e5d85089fe',
            property_analysis_id: '97296dc3-9cc9-403f-b69e-c54fe26217f1',
            type_area: 'preservation',
            geojson: {
              id: '2ec38cee-9f02-4cec-b4d2-2a3adb65396f',
              type: 'Feature',
              geometry: {
                type: 'MultiPolygon',
                coordinates: [
                  [
                    [
                      [-53.29799991, -25.209953603],
                      [-53.298035043, -25.208713908],
                      [-53.298034677, -25.208696281],
                      [-53.298034063, -25.208687497],
                      [-53.298028173, -25.208652773],
                      [-53.298025857, -25.208644278],
                      [-53.298013306, -25.20861137],
                      [-53.298009377, -25.20860349],
                      [-53.297990647, -25.208573663],
                      [-53.297985256, -25.208566701],
                      [-53.297961067, -25.208541101],
                      [-53.297954422, -25.208535325],
                      [-53.297925704, -25.208514936],
                      [-53.297918059, -25.208510567],
                      [-53.297885915, -25.208496173],
                      [-53.297877565, -25.208493379],
                      [-53.29784323, -25.208485532],
                      [-53.297834495, -25.208484421],
                      [-53.297799289, -25.208483423],
                      [-53.297790506, -25.208484038],
                      [-53.297755781, -25.208489928],
                      [-53.297747286, -25.208492244],
                      [-53.297714379, -25.208504795],
                      [-53.297706499, -25.208508724],
                      [-53.297676672, -25.208527453],
                      [-53.29766971, -25.208532844],
                      [-53.29764411, -25.208557033],
                      [-53.297638334, -25.208563679],
                      [-53.297617945, -25.208592397],
                      [-53.297613576, -25.208600042],
                      [-53.297599181, -25.208632186],
                      [-53.297596387, -25.208640536],
                      [-53.29758854, -25.208674871],
                      [-53.29758743, -25.208683605],
                      [-53.297586066, -25.208701184],
                      [-53.297550199, -25.209966752],
                      [-53.297575941, -25.20996624],
                      [-53.297692746, -25.208694209],
                      [-53.297767168, -25.208696343],
                      [-53.297769561, -25.208689189],
                      [-53.29777393, -25.208681544],
                      [-53.297779707, -25.208674899],
                      [-53.297786669, -25.208669508],
                      [-53.297794548, -25.208665579],
                      [-53.297803043, -25.208663263],
                      [-53.297811827, -25.208662648],
                      [-53.297820562, -25.208663759],
                      [-53.297828912, -25.208666553],
                      [-53.297836556, -25.208670922],
                      [-53.297843202, -25.208676698],
                      [-53.297848593, -25.20868366],
                      [-53.297852522, -25.20869154],
                      [-53.297854514, -25.208698847],
                      [-53.297877999, -25.208699521],
                      [-53.297878721, -25.208701671],
                      [-53.298025131, -25.208707546],
                      [-53.297970429, -25.209158454],
                      [-53.297984166, -25.209591805],
                      [-53.297820655, -25.209936639],
                      [-53.297820312, -25.209948755],
                      [-53.29799991, -25.209953603],
                    ],
                  ],
                  [
                    [
                      [-53.290674097, -25.20343271],
                      [-53.289949971, -25.202652721],
                      [-53.289944527, -25.202647107],
                      [-53.289941745, -25.202644361],
                      [-53.289936062, -25.202638989],
                      [-53.289862192, -25.202572151],
                      [-53.289865294, -25.202684856],
                      [-53.290075015, -25.202916085],
                      [-53.290674097, -25.20343271],
                    ],
                  ],
                  [
                    [
                      [-53.297627606, -25.208004592],
                      [-53.297635688, -25.208020261],
                      [-53.297640103, -25.208027879],
                      [-53.297660666, -25.208056474],
                      [-53.297666483, -25.208063084],
                      [-53.297692229, -25.208087117],
                      [-53.297699223, -25.208092466],
                      [-53.297729163, -25.208111015],
                      [-53.297737066, -25.208114896],
                      [-53.29777005, -25.208127247],
                      [-53.297778559, -25.208129512],
                      [-53.297813318, -25.208135191],
                      [-53.297822105, -25.208135752],
                      [-53.297857304, -25.208134541],
                      [-53.297866032, -25.208133378],
                      [-53.297900319, -25.208125323],
                      [-53.297908652, -25.208122479],
                      [-53.297918247, -25.208118112],
                      [-53.297804919, -25.207947118],
                      [-53.297801363, -25.207944399],
                      [-53.297795546, -25.207937789],
                      [-53.297791131, -25.207930171],
                      [-53.297728163, -25.20779181],
                      [-53.297558184, -25.20785205],
                      [-53.297627606, -25.208004592],
                    ],
                  ],
                  [
                    [
                      [-53.297351669, -25.207487261],
                      [-53.29746379, -25.207690148],
                      [-53.297473064, -25.207705143],
                      [-53.297478058, -25.207712395],
                      [-53.29750078, -25.207739305],
                      [-53.297507093, -25.207745444],
                      [-53.297511134, -25.207748666],
                      [-53.297558112, -25.207851892],
                      [-53.297461836, -25.207515052],
                      [-53.297351669, -25.207487261],
                    ],
                  ],
                ],
              },
              properties: {
                id: '2ec38cee-9f02-4cec-b4d2-2a3adb65396f',
                name: 'Área de preservação ambiental',
                year: 2024,
                color: '#2a6318',
                type_area: 'preservation',
                description:
                  'Área de Preservação Permanente a Recompor de Rios até 10 metros',
              },
            },
            geojson_difference: {
              id: 'd772d23c-c46e-455a-a56d-e5bc13601213',
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [-53.289994902, -25.202827755],
                    [-53.290075015, -25.202916085],
                    [-53.290674097, -25.20343271],
                    [-53.289949971, -25.202652721],
                    [-53.289944527, -25.202647107],
                    [-53.289941745, -25.202644361],
                    [-53.289936062, -25.202638989],
                    [-53.289912131, -25.202617336],
                    [-53.289863109, -25.202605468],
                    [-53.289864355, -25.202650724],
                    [-53.289994902, -25.202827755],
                  ],
                ],
              },
              properties: {
                id: 'fe26f100-2221-4dce-9d17-b4a54597005a',
                name: 'Área sobreposta',
                type: 'intersection',
                color: '#ec0b0ba1',
                created_at: '2022-10-28T12:05:33.350959',
                updated_at: null,
                description:
                  'Área retirada da sobreposição na verificação de compliance',
              },
            },
          },
        ],
      },
      weather: {
        latitude: -25.202108,
        longitude: -53.261322,
        generationtime_ms: 26.638031005859375,
        utc_offset_seconds: -10800,
        timezone: 'America/Sao_Paulo',
        timezone_abbreviation: '-03',
        elevation: 715,
        daily_units: {
          time: 'iso8601',
          weathercode: 'wmo code',
          temperature_2m_mean: '°C',
          precipitation_sum: 'mm',
        },
        daily: {
          time: [
            '2020-06-07',
            '2020-06-08',
            '2020-06-09',
            '2020-06-10',
            '2020-06-11',
            '2020-06-12',
            '2020-06-13',
            '2020-06-14',
            '2020-06-15',
            '2020-06-16',
            '2020-06-17',
            '2020-06-18',
            '2020-06-19',
            '2020-06-20',
            '2020-06-21',
            '2020-06-22',
            '2020-06-23',
            '2020-06-24',
            '2020-06-25',
            '2020-06-26',
            '2020-06-27',
            '2020-06-28',
            '2020-06-29',
            '2020-06-30',
            '2020-07-01',
            '2020-07-02',
            '2020-07-03',
            '2020-07-04',
            '2020-07-05',
            '2020-07-06',
            '2020-07-07',
            '2020-07-08',
            '2020-07-09',
            '2020-07-10',
            '2020-07-11',
            '2020-07-12',
            '2020-07-13',
            '2020-07-14',
            '2020-07-15',
            '2020-07-16',
            '2020-07-17',
            '2020-07-18',
            '2020-07-19',
            '2020-07-20',
            '2020-07-21',
            '2020-07-22',
            '2020-07-23',
            '2020-07-24',
            '2020-07-25',
            '2020-07-26',
            '2020-07-27',
            '2020-07-28',
            '2020-07-29',
            '2020-07-30',
            '2020-07-31',
            '2020-08-01',
            '2020-08-02',
            '2020-08-03',
            '2020-08-04',
            '2020-08-05',
            '2020-08-06',
            '2020-08-07',
            '2020-08-08',
            '2020-08-09',
            '2020-08-10',
            '2020-08-11',
            '2020-08-12',
            '2020-08-13',
            '2020-08-14',
            '2020-08-15',
            '2020-08-16',
            '2020-08-17',
            '2020-08-18',
            '2020-08-19',
            '2020-08-20',
            '2020-08-21',
            '2020-08-22',
            '2020-08-23',
            '2020-08-24',
            '2020-08-25',
            '2020-08-26',
            '2020-08-27',
            '2020-08-28',
            '2020-08-29',
            '2020-08-30',
            '2020-08-31',
            '2020-09-01',
            '2020-09-02',
            '2020-09-03',
            '2020-09-04',
            '2020-09-05',
            '2020-09-06',
            '2020-09-07',
            '2020-09-08',
            '2020-09-09',
            '2020-09-10',
            '2020-09-11',
            '2020-09-12',
            '2020-09-13',
            '2020-09-14',
            '2020-09-15',
            '2020-09-16',
            '2020-09-17',
            '2020-09-18',
            '2020-09-19',
            '2020-09-20',
            '2020-09-21',
            '2020-09-22',
            '2020-09-23',
            '2020-09-24',
            '2020-09-25',
            '2020-09-26',
            '2020-09-27',
            '2020-09-28',
            '2020-09-29',
            '2020-09-30',
            '2020-10-01',
            '2020-10-02',
            '2020-10-03',
            '2020-10-04',
            '2020-10-05',
            '2020-10-06',
            '2020-10-07',
            '2020-10-08',
            '2020-10-09',
            '2020-10-10',
            '2020-10-11',
            '2020-10-12',
            '2020-10-13',
            '2020-10-14',
            '2020-10-15',
            '2020-10-16',
            '2020-10-17',
            '2020-10-18',
            '2020-10-19',
            '2020-10-20',
            '2020-10-21',
            '2020-10-22',
            '2020-10-23',
            '2020-10-24',
            '2020-10-25',
            '2020-10-26',
            '2020-10-27',
            '2020-10-28',
            '2020-10-29',
            '2020-10-30',
            '2020-10-31',
            '2020-11-01',
            '2020-11-02',
            '2020-11-03',
            '2020-11-04',
            '2020-11-05',
            '2020-11-06',
            '2020-11-07',
            '2020-11-08',
            '2020-11-09',
            '2020-11-10',
            '2020-11-11',
            '2020-11-12',
            '2020-11-13',
            '2020-11-14',
            '2020-11-15',
            '2020-11-16',
            '2020-11-17',
            '2020-11-18',
            '2020-11-19',
            '2020-11-20',
            '2020-11-21',
            '2020-11-22',
            '2020-11-23',
            '2020-11-24',
            '2020-11-25',
            '2020-11-26',
            '2020-11-27',
            '2020-11-28',
            '2020-11-29',
            '2020-11-30',
            '2020-12-01',
            '2020-12-02',
            '2020-12-03',
            '2020-12-04',
            '2020-12-05',
            '2020-12-06',
            '2020-12-07',
            '2020-12-08',
            '2020-12-09',
            '2020-12-10',
            '2020-12-11',
            '2020-12-12',
            '2020-12-13',
            '2020-12-14',
            '2020-12-15',
            '2020-12-16',
            '2020-12-17',
            '2020-12-18',
            '2020-12-19',
            '2020-12-20',
            '2020-12-21',
            '2020-12-22',
            '2020-12-23',
            '2020-12-24',
            '2020-12-25',
            '2020-12-26',
            '2020-12-27',
            '2020-12-28',
            '2020-12-29',
            '2020-12-30',
            '2020-12-31',
            '2021-01-01',
            '2021-01-02',
            '2021-01-03',
            '2021-01-04',
            '2021-01-05',
            '2021-01-06',
            '2021-01-07',
            '2021-01-08',
            '2021-01-09',
            '2021-01-10',
            '2021-01-11',
            '2021-01-12',
            '2021-01-13',
            '2021-01-14',
            '2021-01-15',
            '2021-01-16',
            '2021-01-17',
            '2021-01-18',
            '2021-01-19',
            '2021-01-20',
            '2021-01-21',
            '2021-01-22',
            '2021-01-23',
            '2021-01-24',
            '2021-01-25',
            '2021-01-26',
            '2021-01-27',
            '2021-01-28',
            '2021-01-29',
            '2021-01-30',
            '2021-01-31',
            '2021-02-01',
            '2021-02-02',
            '2021-02-03',
            '2021-02-04',
            '2021-02-05',
            '2021-02-06',
            '2021-02-07',
            '2021-02-08',
            '2021-02-09',
            '2021-02-10',
            '2021-02-11',
            '2021-02-12',
            '2021-02-13',
            '2021-02-14',
            '2021-02-15',
            '2021-02-16',
            '2021-02-17',
            '2021-02-18',
            '2021-02-19',
            '2021-02-20',
            '2021-02-21',
            '2021-02-22',
            '2021-02-23',
            '2021-02-24',
            '2021-02-25',
            '2021-02-26',
            '2021-02-27',
            '2021-02-28',
            '2021-03-01',
            '2021-03-02',
            '2021-03-03',
            '2021-03-04',
            '2021-03-05',
            '2021-03-06',
            '2021-03-07',
            '2021-03-08',
            '2021-03-09',
            '2021-03-10',
            '2021-03-11',
            '2021-03-12',
            '2021-03-13',
            '2021-03-14',
            '2021-03-15',
            '2021-03-16',
            '2021-03-17',
            '2021-03-18',
            '2021-03-19',
            '2021-03-20',
            '2021-03-21',
            '2021-03-22',
            '2021-03-23',
            '2021-03-24',
            '2021-03-25',
            '2021-03-26',
            '2021-03-27',
            '2021-03-28',
            '2021-03-29',
            '2021-03-30',
            '2021-03-31',
            '2021-04-01',
            '2021-04-02',
            '2021-04-03',
            '2021-04-04',
            '2021-04-05',
            '2021-04-06',
            '2021-04-07',
            '2021-04-08',
            '2021-04-09',
            '2021-04-10',
            '2021-04-11',
            '2021-04-12',
            '2021-04-13',
            '2021-04-14',
            '2021-04-15',
            '2021-04-16',
            '2021-04-17',
            '2021-04-18',
            '2021-04-19',
            '2021-04-20',
            '2021-04-21',
            '2021-04-22',
            '2021-04-23',
            '2021-04-24',
            '2021-04-25',
            '2021-04-26',
            '2021-04-27',
            '2021-04-28',
            '2021-04-29',
            '2021-04-30',
            '2021-05-01',
            '2021-05-02',
            '2021-05-03',
            '2021-05-04',
            '2021-05-05',
            '2021-05-06',
            '2021-05-07',
            '2021-05-08',
            '2021-05-09',
            '2021-05-10',
            '2021-05-11',
            '2021-05-12',
            '2021-05-13',
            '2021-05-14',
            '2021-05-15',
            '2021-05-16',
            '2021-05-17',
            '2021-05-18',
            '2021-05-19',
            '2021-05-20',
            '2021-05-21',
            '2021-05-22',
            '2021-05-23',
            '2021-05-24',
            '2021-05-25',
            '2021-05-26',
            '2021-05-27',
            '2021-05-28',
            '2021-05-29',
            '2021-05-30',
            '2021-05-31',
            '2021-06-01',
            '2021-06-02',
            '2021-06-03',
            '2021-06-04',
            '2021-06-05',
            '2021-06-06',
            '2021-06-07',
            '2021-06-08',
            '2021-06-09',
            '2021-06-10',
            '2021-06-11',
            '2021-06-12',
            '2021-06-13',
            '2021-06-14',
            '2021-06-15',
            '2021-06-16',
            '2021-06-17',
            '2021-06-18',
            '2021-06-19',
            '2021-06-20',
            '2021-06-21',
            '2021-06-22',
            '2021-06-23',
            '2021-06-24',
            '2021-06-25',
            '2021-06-26',
            '2021-06-27',
            '2021-06-28',
            '2021-06-29',
            '2021-06-30',
            '2021-07-01',
            '2021-07-02',
            '2021-07-03',
            '2021-07-04',
            '2021-07-05',
            '2021-07-06',
            '2021-07-07',
            '2021-07-08',
            '2021-07-09',
            '2021-07-10',
            '2021-07-11',
            '2021-07-12',
            '2021-07-13',
            '2021-07-14',
            '2021-07-15',
            '2021-07-16',
            '2021-07-17',
            '2021-07-18',
            '2021-07-19',
            '2021-07-20',
            '2021-07-21',
            '2021-07-22',
            '2021-07-23',
            '2021-07-24',
            '2021-07-25',
            '2021-07-26',
            '2021-07-27',
            '2021-07-28',
            '2021-07-29',
            '2021-07-30',
            '2021-07-31',
            '2021-08-01',
            '2021-08-02',
            '2021-08-03',
            '2021-08-04',
            '2021-08-05',
            '2021-08-06',
            '2021-08-07',
            '2021-08-08',
            '2021-08-09',
            '2021-08-10',
            '2021-08-11',
            '2021-08-12',
            '2021-08-13',
            '2021-08-14',
            '2021-08-15',
            '2021-08-16',
            '2021-08-17',
            '2021-08-18',
            '2021-08-19',
            '2021-08-20',
            '2021-08-21',
            '2021-08-22',
            '2021-08-23',
            '2021-08-24',
            '2021-08-25',
            '2021-08-26',
            '2021-08-27',
            '2021-08-28',
            '2021-08-29',
            '2021-08-30',
            '2021-08-31',
            '2021-09-01',
            '2021-09-02',
            '2021-09-03',
            '2021-09-04',
            '2021-09-05',
            '2021-09-06',
            '2021-09-07',
            '2021-09-08',
            '2021-09-09',
            '2021-09-10',
            '2021-09-11',
            '2021-09-12',
            '2021-09-13',
            '2021-09-14',
            '2021-09-15',
            '2021-09-16',
            '2021-09-17',
            '2021-09-18',
            '2021-09-19',
            '2021-09-20',
            '2021-09-21',
            '2021-09-22',
            '2021-09-23',
            '2021-09-24',
            '2021-09-25',
            '2021-09-26',
            '2021-09-27',
            '2021-09-28',
            '2021-09-29',
            '2021-09-30',
            '2021-10-01',
            '2021-10-02',
            '2021-10-03',
            '2021-10-04',
            '2021-10-05',
            '2021-10-06',
            '2021-10-07',
            '2021-10-08',
            '2021-10-09',
            '2021-10-10',
            '2021-10-11',
            '2021-10-12',
            '2021-10-13',
            '2021-10-14',
            '2021-10-15',
            '2021-10-16',
            '2021-10-17',
            '2021-10-18',
            '2021-10-19',
            '2021-10-20',
            '2021-10-21',
            '2021-10-22',
            '2021-10-23',
            '2021-10-24',
            '2021-10-25',
            '2021-10-26',
            '2021-10-27',
            '2021-10-28',
            '2021-10-29',
            '2021-10-30',
            '2021-10-31',
            '2021-11-01',
            '2021-11-02',
            '2021-11-03',
            '2021-11-04',
            '2021-11-05',
            '2021-11-06',
            '2021-11-07',
            '2021-11-08',
            '2021-11-09',
            '2021-11-10',
            '2021-11-11',
            '2021-11-12',
            '2021-11-13',
            '2021-11-14',
            '2021-11-15',
            '2021-11-16',
            '2021-11-17',
            '2021-11-18',
          ],
          weathercode: [
            0, 51, 63, 55, 51, 1, 61, 55, 1, 0, 1, 2, 1, 0, 0, 0, 1, 0, 61, 61,
            61, 3, 55, 63, 51, 3, 0, 1, 2, 61, 61, 61, 3, 0, 2, 51, 55, 3, 0, 1,
            1, 0, 0, 1, 1, 1, 1, 1, 61, 51, 3, 61, 3, 1, 2, 0, 0, 0, 0, 0, 0, 1,
            0, 0, 0, 1, 61, 61, 53, 63, 61, 61, 63, 65, 53, 2, 0, 0, 0, 0, 0, 0,
            0, 0, 3, 1, 1, 1, 51, 1, 1, 2, 51, 3, 2, 1, 2, 2, 1, 3, 1, 3, 63, 1,
            1, 51, 51, 51, 1, 0, 1, 1, 63, 63, 51, 1, 1, 1, 2, 0, 1, 1, 1, 51,
            63, 1, 0, 0, 2, 65, 63, 51, 1, 2, 1, 1, 51, 1, 1, 63, 51, 65, 51, 1,
            61, 51, 51, 1, 2, 1, 0, 0, 0, 1, 2, 61, 63, 63, 63, 51, 1, 63, 51,
            51, 63, 51, 1, 1, 1, 1, 0, 1, 3, 51, 61, 51, 63, 63, 51, 63, 65, 65,
            53, 51, 1, 0, 1, 51, 61, 65, 53, 51, 63, 63, 61, 65, 65, 51, 1, 51,
            1, 1, 53, 2, 63, 61, 61, 63, 51, 51, 53, 55, 65, 61, 63, 51, 63, 61,
            53, 63, 51, 63, 61, 61, 65, 53, 63, 63, 61, 63, 63, 63, 63, 53, 63,
            53, 63, 53, 63, 63, 51, 63, 61, 3, 0, 0, 1, 2, 2, 63, 53, 61, 63,
            63, 51, 0, 0, 0, 1, 51, 1, 2, 63, 53, 51, 53, 51, 55, 63, 63, 63,
            65, 53, 51, 55, 0, 1, 0, 0, 1, 1, 2, 51, 63, 3, 51, 51, 61, 63, 55,
            61, 61, 61, 61, 61, 61, 61, 0, 1, 1, 2, 51, 2, 3, 2, 1, 1, 1, 3, 51,
            51, 61, 3, 53, 53, 51, 0, 1, 0, 1, 2, 53, 53, 3, 0, 2, 0, 0, 1, 51,
            1, 1, 2, 63, 51, 0, 1, 2, 51, 61, 1, 51, 1, 3, 2, 1, 0, 0, 63, 51,
            51, 1, 1, 2, 3, 51, 51, 51, 51, 3, 51, 51, 0, 1, 63, 63, 63, 55, 53,
            51, 0, 0, 0, 1, 1, 2, 63, 63, 51, 53, 51, 1, 53, 53, 1, 63, 55, 2,
            0, 1, 1, 1, 1, 0, 3, 0, 0, 0, 0, 0, 1, 1, 1, 61, 55, 51, 3, 1, 1, 1,
            0, 0, 0, 0, 1, 63, 1, 1, 1, 51, 53, 0, 1, 1, 2, 3, 3, 1, 1, 51, 53,
            63, 63, 53, 51, 53, 0, 1, 0, 1, 0, 0, 2, 51, 61, 61, 3, 51, 2, 0, 0,
            1, 1, 3, 2, 61, 0, 0, 63, 61, 1, 61, 1, 1, 65, 61, 63, 1, 1, 51, 1,
            3, 1, 51, 3, 1, 1, 0, 2, 53, 51, 63, 61, 63, 65, 51, 63, 63, 51, 63,
            51, 53, 51, 1, 63, 63, 51, 61, 53, 63, 2, 2, 0, 65, 55, 3, 1, 1, 51,
            63, 63, 63, 53, 51, 53, 51, 51, 61, 63, 63, 51, 61, 55, 51, 2, 3, 3,
            3, 51, 65,
          ],
          temperature_2m_mean: [
            14, 19.4, 19.8, 20.9, 22.1, 21.6, 18.8, 16.6, 18.1, 18.7, 19.5,
            19.8, 20.2, 20.4, 20.8, 20.7, 20, 20, 17.9, 11.5, 13.3, 14.9, 15.3,
            16.6, 8.4, 7.6, 8.7, 14.7, 18.9, 19, 20.1, 12.3, 9.8, 15.1, 19.7,
            19.7, 19.1, 14.4, 16.3, 18, 20.7, 20.6, 20.1, 19, 19.7, 20.1, 20.1,
            20.7, 11.7, 14.9, 19.4, 17.7, 11.1, 14.1, 16.7, 18.8, 19.1, 18.3,
            18.8, 18.2, 19.1, 19.2, 19.7, 20.5, 21.1, 22.7, 19.7, 17.3, 19.5,
            17.8, 17.6, 17.9, 17.5, 14.5, 10.8, 5.8, 9.2, 13.2, 16.3, 18.3,
            18.9, 21.2, 22.1, 23.1, 23.9, 23.9, 22.5, 21.4, 21.5, 21, 24.8, 25,
            22.5, 21.8, 25.1, 25.8, 26.6, 27.2, 27.1, 23.7, 22.4, 23.5, 18.7,
            19.8, 21.9, 18.7, 18.3, 19, 19.5, 22.1, 24.7, 27.4, 26.9, 21.2,
            24.9, 28.5, 29.7, 30.5, 29.9, 26, 25.7, 25.7, 28.2, 24.9, 20.2,
            18.7, 21.4, 23.8, 24.7, 23.5, 19.9, 21, 20.5, 21.7, 23.7, 24.8,
            25.1, 24.7, 24.2, 23.3, 25.1, 21.4, 19.3, 22, 19.1, 18.8, 19, 19.6,
            19.4, 21.2, 21.3, 20.2, 23, 25.9, 27.6, 21, 21.2, 21.1, 21.8, 23,
            25.7, 22.5, 21.9, 22.5, 18.4, 18.5, 19.9, 21.3, 23.1, 24.1, 25,
            26.8, 26.5, 25.1, 25.7, 25.8, 22.8, 23.2, 24.6, 21.5, 20.9, 20.1,
            20, 21.6, 23.2, 24.1, 24.2, 24.8, 24.5, 23.7, 21.7, 22.6, 22.2,
            21.4, 23, 24.6, 23.1, 22.2, 22.3, 23.9, 24.3, 23.2, 23, 24.4, 23.4,
            23.3, 23.6, 22.2, 23.2, 23.5, 22.6, 22.2, 20.8, 22.5, 21.6, 22.6,
            23.5, 24.4, 25.3, 25.1, 24.5, 23.7, 23.2, 22.9, 21.9, 21.7, 21.1,
            20.7, 21.2, 21.6, 22.2, 21.8, 21.3, 21.9, 22.6, 21.7, 21.9, 22.4,
            21, 22, 23, 24.1, 21.7, 19.3, 18.4, 21, 21.8, 22, 22.1, 20.4, 20.5,
            21.3, 21.7, 21.4, 22.1, 21, 19.6, 20.2, 22.8, 24, 24.4, 25.4, 24.3,
            23.6, 23.1, 22.5, 22.5, 22.1, 22.6, 21.8, 20.9, 20.7, 20.9, 22.5,
            22.5, 21.6, 20.6, 21.8, 22.3, 23.7, 24.4, 24.8, 25.2, 22.5, 21.6,
            23, 23.9, 24.1, 23.8, 23.4, 24, 24.7, 24.2, 24.1, 22.2, 20.4, 17.9,
            17.6, 18.6, 20.6, 22.1, 22.2, 21.9, 20.7, 21.9, 22, 23.3, 23.9, 24,
            21.3, 21.8, 21.2, 21.2, 19.2, 19.8, 20.1, 20.2, 20.5, 19.7, 19.3,
            20.1, 18.7, 16.9, 15.9, 14.6, 15.5, 16.8, 17.7, 19.3, 21.3, 21.8,
            22.3, 22.5, 15.6, 12.2, 16, 18.3, 20, 19.3, 15.7, 14.8, 15.8, 16.4,
            17.8, 17.8, 15.8, 17.6, 20.6, 21.7, 17.6, 11.7, 9.3, 13.3, 17.4,
            20.4, 22, 21.1, 16.3, 16.4, 17.3, 19.3, 19.7, 21, 21.8, 17.5, 16.8,
            17.1, 17.3, 17.3, 15.2, 12.6, 15.1, 15.4, 15.4, 12.9, 12, 13.9, 13,
            12.4, 13.6, 16, 17.2, 17.4, 18.6, 20.7, 13.5, 5.3, 3.2, 5.3, 10.7,
            13.1, 14, 15.4, 16.6, 16.3, 15.2, 16.3, 15.8, 17.6, 17.9, 18.2,
            19.4, 20.4, 18.4, 15, 12.7, 7.4, 5.6, 10.3, 14.8, 18.8, 20, 20.5,
            21.1, 22.7, 13.8, 5.3, 3.7, 8.2, 12.7, 14.3, 13.6, 14.1, 14.7, 16.6,
            18.9, 20.1, 20.4, 22.3, 19.7, 14.8, 14.1, 13.6, 16.8, 18.8, 20.7,
            22.9, 24.5, 24.9, 24.4, 23.8, 23.5, 24.8, 25.2, 24.2, 14.1, 13.7,
            17.2, 18.4, 18.4, 19.3, 19.8, 21.3, 23.6, 24.7, 21.7, 23.5, 25.7,
            22.3, 15.6, 13.6, 17.5, 24.1, 27.7, 21.7, 18.4, 19.1, 20.1, 24.4,
            26.9, 27.5, 23.1, 20.1, 18.8, 19.9, 20.9, 24.2, 25.6, 26, 22.1,
            24.3, 20.6, 18.5, 19.2, 18.3, 19.4, 21.7, 20.3, 20.4, 18.3, 18.8,
            19.1, 20.9, 22.8, 21.2, 19.5, 18.7, 18.3, 18.9, 17.5, 17.6, 18.6,
            20.8, 20.2, 17.8, 18.5, 22, 22.5, 22.9, 22.5, 21, 20.6, 20.9, 21.9,
            23, 24.4, 24.5, 22.5, 22.4, 21.8, 22.7, 20.8, 20.8, 20.8, 20.7,
            21.3, 23.6, 25.1, 24.3, 20.7,
          ],
          precipitation_sum: [
            0, 1.2, 27.1, 4.4, 0.1, 0, 7.5, 2.9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            10.6, 11.6, 9.2, 0, 4, 13, 0.7, 0, 0, 0, 0, 4.3, 3.4, 13.4, 0, 0, 0,
            0.3, 4.4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4.7, 0.1, 0, 5.6, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8, 12.6, 0.9, 27.8, 7, 4.7,
            20.3, 62.8, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0,
            0.1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 14.9, 0, 0, 0.1, 0.3, 0.9, 0, 0, 0,
            0, 9.7, 9.3, 0.1, 0, 0, 0, 0, 0, 0, 0, 0, 1.2, 16.6, 0, 0, 0, 0,
            12.6, 14.4, 1.8, 0, 0, 0, 0, 0.3, 0, 0, 6.4, 0.1, 27.1, 0.9, 0, 6.7,
            0.9, 0.4, 0, 0, 0, 0, 0, 0, 0, 0, 10.4, 7.9, 21.5, 6.1, 0.2, 0, 7.8,
            0.5, 0.4, 29.5, 0.4, 0, 0, 0, 0, 0, 0, 0, 0.3, 3.1, 0.5, 8.6, 6.7,
            0.3, 31.7, 50.7, 50.8, 3.3, 0.3, 0, 0, 0, 0.2, 3, 20.3, 1.5, 2, 9.1,
            14.3, 8, 14.2, 28.8, 1.5, 0, 0.5, 0, 0, 0.5, 0, 9, 7.3, 2.4, 20.1,
            0.7, 0.8, 1.7, 2.4, 43, 6.7, 9.9, 0.2, 7.3, 2.8, 1.5, 8.2, 0.6, 6.1,
            2.5, 4, 42.8, 4.8, 21.6, 24.5, 9.3, 17.3, 14.7, 11.8, 15.8, 2.2,
            7.1, 3.8, 17.2, 2.4, 11.7, 7.9, 1.2, 5.8, 10, 0, 0, 0, 0, 0, 0, 7.8,
            2.8, 2.9, 11, 6.7, 1.5, 0, 0, 0, 0, 0.1, 0, 0, 4.9, 1.5, 0.6, 1.3,
            0.9, 2.6, 11.5, 21.6, 12.8, 13.1, 3.1, 0.2, 2.6, 0, 0, 0, 0, 0, 0,
            0, 0.5, 10.9, 0, 0.1, 0.2, 2.2, 10.1, 3.3, 4.7, 2.7, 8.3, 4, 4, 8.7,
            9, 0, 0, 0, 0, 0.3, 0, 0, 0, 0, 0, 0, 0, 0.3, 0.2, 1.8, 0, 1.5, 0.9,
            0.9, 0, 0, 0, 0, 0, 2.9, 3.3, 0, 0, 0, 0, 0, 0, 0.2, 0, 0, 0, 17.3,
            0.1, 0, 0, 0, 0.6, 9.7, 0, 0.2, 0, 0, 0, 0, 0, 0, 4.6, 1.6, 0.1, 0,
            0, 0, 0, 0.4, 0.5, 0.1, 1.8, 0, 1.1, 0.2, 0, 0, 28.2, 18.1, 11.3,
            2.7, 5.6, 0.1, 0, 0, 0, 0, 0, 0, 9.2, 30.2, 0.8, 6.2, 1.7, 0, 3.1,
            1.9, 0, 15.7, 8.7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            8.1, 3.3, 0.9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 18.4, 0, 0, 0, 0.3, 1.1,
            0, 0, 0, 0, 0, 0, 0, 0, 0.4, 2.5, 21.3, 26.4, 2.8, 0.6, 1.4, 0, 0,
            0, 0, 0, 0, 0, 0.1, 3, 11.5, 0, 0.4, 0, 0, 0, 0, 0, 0, 0, 4.5, 0, 0,
            21.6, 4.2, 0, 4.7, 0, 0, 24.7, 5.2, 29.9, 0, 0, 0.2, 0, 0, 0, 0.3,
            0, 0, 0, 0, 0, 1.6, 0.1, 17.9, 11.5, 28.1, 34.7, 0.2, 18.3, 19.4,
            0.7, 28.4, 0.2, 1.5, 0.3, 0, 12, 47.8, 1.2, 13.8, 1.6, 4.2, 0, 0, 0,
            33.4, 4.8, 0, 0, 0, 0.1, 8.4, 13.2, 6.6, 0.8, 0.2, 1.2, 0.2, 1, 2.2,
            9, 10.9, 0.3, 4.9, 3.5, 0.4, 0, 0, 0, 0, 0.6, 46.2,
          ],
        },
      },
      chart_ndvi: {
        id: 'd3e1a708-0913-4b3a-943d-4c6c7f35f056',
        data: [
          {
            line: {
              color: 'grey',
              shape: 'spline',
              width: 1,
            },
            mode: 'lines',
            name: 'Indice Vegetativo',
            showlegend: true,
            x: [
              '2020-06-24',
              '2020-07-04',
              '2020-07-09',
              '2020-07-14',
              '2020-07-19',
              '2020-07-24',
              '2020-08-03',
              '2020-08-08',
              '2020-08-23',
              '2020-08-28',
              '2020-09-07',
              '2020-09-12',
              '2020-09-22',
              '2020-10-02',
              '2020-10-07',
              '2020-10-12',
              '2020-10-17',
              '2020-10-22',
              '2020-10-27',
              '2020-11-01',
              '2020-11-06',
              '2020-12-11',
              '2020-12-26',
              '2021-02-19',
              '2021-03-11',
              '2021-03-16',
              '2021-03-31',
              '2021-04-10',
              '2021-04-15',
              '2021-04-20',
              '2021-04-30',
              '2021-05-10',
              '2021-05-20',
              '2021-05-25',
              '2021-06-04',
              '2021-06-14',
              '2021-07-09',
              '2021-07-19',
              '2021-07-24',
              '2021-07-29',
              '2021-08-03',
              '2021-08-08',
              '2021-08-18',
              '2021-09-02',
              '2021-09-07',
              '2021-09-22',
              '2021-09-27',
              '2021-10-22',
              '2021-11-01',
              '2021-11-06',
            ],
            y: [
              0.7710650829511565, 0.8347968696571877, 0.8789012528605571,
              0.9033782325612645, 0.90822780875931, 0.8974598566582384,
              0.8378585550854012, 0.7484936551593193, 0.6269203892970819,
              0.4869789298758033, 0.356620145453581, 0.2519640370471329,
              0.18765590934243598, 0.15551253780019028, 0.16808742051021985,
              0.16002479633807679, 0.12522729836482943, 0.1578463720351309,
              0.2664810409928742, 0.46724325602965566, 0.6030845407188999,
              0.6531272936837929, 0.6459681437856772, 0.5850531424764086,
              0.4609682735275597, 0.29999740054329144, 0.24246035846343375,
              0.2672589072792833, 0.36277784656383705, 0.40734712950232255,
              0.4204527488423163, 0.42862950541457256, 0.4332113694576577,
              0.46171603327205674, 0.4983618759060632, 0.5528749036150847,
              0.610656982189191, 0.6671154676749131, 0.7157895024013189,
              0.7468834800110948, 0.7584575451926524, 0.7604376181725656,
              0.7679548272938405, 0.7083318171388279, 0.6093008139264231,
              0.5674567493561853, 0.46890949966325807, 0.35055008923372694,
              0.2123785180675919, 0.05439478616485294,
            ],
            type: 'scatter',
          },
          {
            line: {
              color: 'blue',
              shape: 'spline',
              width: 4,
            },
            mode: 'lines',
            name: 'Data indicada de plantio no projeto: (07/06 10/06)',
            showlegend: true,
            x: [
              '2020-06-24',
              '2020-07-04',
              '2020-07-09',
              '2020-07-14',
              '2020-07-19',
              '2020-07-24',
              '2020-08-03',
              '2020-08-08',
              '2020-08-23',
              '2020-08-28',
              '2020-09-07',
              '2020-09-12',
              '2020-09-22',
              '2020-10-02',
              '2020-10-07',
              '2020-10-12',
              '2020-10-17',
              '2020-10-22',
              '2020-10-27',
              '2020-11-01',
              '2020-11-06',
              '2020-12-11',
              '2020-12-26',
              '2021-02-19',
              '2021-03-11',
              '2021-03-16',
              '2021-03-31',
              '2021-04-10',
              '2021-04-15',
              '2021-04-20',
              '2021-04-30',
              '2021-05-10',
              '2021-05-20',
              '2021-05-25',
              '2021-06-04',
              '2021-06-14',
              '2021-07-09',
              '2021-07-19',
              '2021-07-24',
              '2021-07-29',
              '2021-08-03',
              '2021-08-08',
              '2021-08-18',
              '2021-09-02',
              '2021-09-07',
              '2021-09-22',
              '2021-09-27',
              '2021-10-22',
              '2021-11-01',
              '2021-11-06',
            ],
            y: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            type: 'scatter',
          },
          {
            line: {
              color: 'orange',
              shape: 'spline',
              width: 4,
            },
            mode: 'markers',
            name: 'Data indicada de colheita no projeto: (15/10 18/10)',
            showlegend: true,
            x: [
              '2020-06-24',
              '2020-07-04',
              '2020-07-09',
              '2020-07-14',
              '2020-07-19',
              '2020-07-24',
              '2020-08-03',
              '2020-08-08',
              '2020-08-23',
              '2020-08-28',
              '2020-09-07',
              '2020-09-12',
              '2020-09-22',
              '2020-10-02',
              '2020-10-07',
              '2020-10-12',
              '2020-10-17',
              '2020-10-22',
              '2020-10-27',
              '2020-11-01',
              '2020-11-06',
              '2020-12-11',
              '2020-12-26',
              '2021-02-19',
              '2021-03-11',
              '2021-03-16',
              '2021-03-31',
              '2021-04-10',
              '2021-04-15',
              '2021-04-20',
              '2021-04-30',
              '2021-05-10',
              '2021-05-20',
              '2021-05-25',
              '2021-06-04',
              '2021-06-14',
              '2021-07-09',
              '2021-07-19',
              '2021-07-24',
              '2021-07-29',
              '2021-08-03',
              '2021-08-08',
              '2021-08-18',
              '2021-09-02',
              '2021-09-07',
              '2021-09-22',
              '2021-09-27',
              '2021-10-22',
              '2021-11-01',
              '2021-11-06',
            ],
            y: [
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
            ],
            type: 'scatter',
          },
          {
            line: {
              color: 'aqua',
              shape: 'spline',
              width: 4,
            },
            marker: {
              color: 'aqua',
              line: {
                color: 'gray',
                width: 1,
              },
              size: 10,
            },
            mode: 'markers',
            name: 'Plantio realizado (24/07/2021)',
            showlegend: true,
            x: ['2021-07-24'],
            y: [0.7157894968986511],
            type: 'scatter',
          },
          {
            line: {
              color: 'green',
              shape: 'spline',
              width: 4,
            },
            marker: {
              color: 'green',
              line: {
                color: 'gray',
                width: 1,
              },
              size: 10,
            },
            mode: 'markers',
            name: 'Pico vegetativo (18/08/2021)',
            showlegend: true,
            x: ['2021-08-18'],
            y: [0.7679548263549805],
            type: 'scatter',
          },
          {
            line: {
              color: 'yellow',
              shape: 'spline',
              width: 4,
            },
            marker: {
              color: 'yellow',
              line: {
                color: 'gray',
                width: 1,
              },
              size: 10,
            },
            mode: 'markers',
            name: 'Inicio de senescência (27/09/2021)',
            showlegend: true,
            x: ['2021-09-27'],
            y: [0.46890950202941895],
            type: 'scatter',
          },
          {
            line: {
              color: 'red',
              shape: 'spline',
              width: 4,
            },
            marker: {
              color: 'red',
              line: {
                color: 'gray',
                width: 1,
              },
              size: 10,
            },
            mode: 'markers',
            name: 'Colhido (06/11/2021)',
            showlegend: true,
            x: ['2021-11-06'],
            y: [0.054394785314798355],
            type: 'scatter',
          },
        ],
      },
      crop_type: {
        message: 'Unable to parse error from calculate-ms (not string)',
      },
      ndvi: {
        date_begin: '2020-06-07T03:00:00.000Z',
        date_end: '2021-11-18T03:00:00.000Z',
        satellite: 'COPERNICUS/S2_SR_HARMONIZED',
        cloud_coverage_limit: 20,
        images: [
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200614T134221_20200614T134336_T21JZN',
            date: '2020-06-14',
            cloud_cover: 7.255451,
            properties: {},
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200619T134219_20200619T134213_T21JZN',
            date: '2020-06-19',
            cloud_cover: 10.968955,
            properties: {},
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200624T134221_20200624T134216_T21JZN',
            date: '2020-06-24',
            cloud_cover: 0.697612,
            properties: {
              NDVI_min: 0.6394479585968947,
              NDVI_mean: 0.7704352715468722,
              NDVI_max: 0.8706500328299409,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200704T134221_20200704T134215_T22JBT',
            date: '2020-07-04',
            cloud_cover: 0.47478,
            properties: {
              NDVI_min: 0.7470621152770005,
              NDVI_mean: 0.8448768068366377,
              NDVI_max: 0.9072642967542504,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200709T134209_20200709T134758_T21JZN',
            date: '2020-07-09',
            cloud_cover: 2.374641,
            properties: {
              NDVI_min: 0.7468354430379747,
              NDVI_mean: 0.8857923165177531,
              NDVI_max: 0.9427244582043344,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200714T134221_20200714T134345_T22JBT',
            date: '2020-07-14',
            cloud_cover: 0.05433,
            properties: {
              NDVI_min: 0.7942012507106311,
              NDVI_mean: 0.895148204732289,
              NDVI_max: 0.9373054690973767,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200719T134209_20200719T134212_T22JBT',
            date: '2020-07-19',
            cloud_cover: 0.024187,
            properties: {
              NDVI_min: 0.7777777777777778,
              NDVI_mean: 0.878692605138784,
              NDVI_max: 0.9212880143112702,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200724T134221_20200724T134216_T21JZN',
            date: '2020-07-24',
            cloud_cover: 0.108029,
            properties: {
              NDVI_min: 0.7232091690544412,
              NDVI_mean: 0.8835739567349536,
              NDVI_max: 0.9296573632856818,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200803T134221_20200803T134216_T21JZN',
            date: '2020-08-03',
            cloud_cover: 0.027173,
            properties: {
              NDVI_min: 0.7295980511571255,
              NDVI_mean: 0.8703888796478662,
              NDVI_max: 0.9295443870361673,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200808T134219_20200808T134800_T22JBT',
            date: '2020-08-08',
            cloud_cover: 0.023683,
            properties: {
              NDVI_min: 0.7026464466250372,
              NDVI_mean: 0.8447609640230543,
              NDVI_max: 0.914396887159533,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200823T134221_20200823T134216_T21JZN',
            date: '2020-08-23',
            cloud_cover: 0.024595,
            properties: {
              NDVI_min: 0.5573706728899261,
              NDVI_mean: 0.7311736397104208,
              NDVI_max: 0.8361918095904796,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200828T134219_20200828T134214_T22JBT',
            date: '2020-08-28',
            cloud_cover: 0.015255,
            properties: {
              NDVI_min: 0.4464555052790347,
              NDVI_mean: 0.6184501660749153,
              NDVI_max: 0.7635615413393191,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200907T134219_20200907T134901_T22JBT',
            date: '2020-09-07',
            cloud_cover: 11.279269,
            properties: {
              NDVI_min: 0.24339516582349635,
              NDVI_mean: 0.3090060015443146,
              NDVI_max: 0.46301369863013697,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200912T134211_20200912T134811_T21JZN',
            date: '2020-09-12',
            cloud_cover: 2.437172,
            properties: {
              NDVI_min: 0.22371134020618558,
              NDVI_mean: 0.2863594321567416,
              NDVI_max: 0.6257203518350015,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20200922T134221_20200922T134643_T22JBT',
            date: '2020-09-22',
            cloud_cover: 12.384813,
            properties: {
              NDVI_min: 0.14869888475836432,
              NDVI_mean: 0.18793484340757452,
              NDVI_max: 0.7547719184729861,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201002T134221_20201002T134216_T22JBT',
            date: '2020-10-02',
            cloud_cover: 0.015873,
            properties: {
              NDVI_min: 0.1366171003717472,
              NDVI_mean: 0.17607439500974537,
              NDVI_max: 0.7171898355754858,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201007T134219_20201007T134214_T22JBT',
            date: '2020-10-07',
            cloud_cover: 0.785084,
            properties: {
              NDVI_min: 0.16071428571428573,
              NDVI_mean: 0.18647676369609648,
              NDVI_max: 0.5415265200517464,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201012T134221_20201012T134216_T22JBT',
            date: '2020-10-12',
            cloud_cover: 2.472109,
            properties: {
              NDVI_min: 0.13761467889908258,
              NDVI_mean: 0.17328988678503945,
              NDVI_max: 0.7118402282453637,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201017T134219_20201017T134214_T21JZN',
            date: '2020-10-17',
            cloud_cover: 11.475722,
            properties: {
              NDVI_min: 0.1297539149888143,
              NDVI_mean: 0.16438810527080439,
              NDVI_max: 0.6364153627311522,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201022T134221_20201022T134216_T22JBT',
            date: '2020-10-22',
            cloud_cover: 0.020332,
            properties: {
              NDVI_min: 0.12507409602845287,
              NDVI_mean: 0.1661515347654734,
              NDVI_max: 0.712448860315605,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201027T134219_20201027T134214_T22JBT',
            date: '2020-10-27',
            cloud_cover: 19.351289,
            properties: {
              NDVI_min: -0.06607192640089211,
              NDVI_mean: 0.21615310572423496,
              NDVI_max: 0.463231850117096,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201101T134221_20201101T134216_T22JBT',
            date: '2020-11-01',
            cloud_cover: 5.234044,
            properties: {
              NDVI_min: 0.02562396006655574,
              NDVI_mean: 0.19548248512016,
              NDVI_max: 0.5,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201106T134209_20201106T134212_T21JZN',
            date: '2020-11-06',
            cloud_cover: 0.02633,
            properties: {
              NDVI_min: 0.2030970815961882,
              NDVI_mean: 0.2865833563294564,
              NDVI_max: 0.7109704641350211,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201121T134211_20201121T134213_T22JBT',
            date: '2020-11-21',
            cloud_cover: 14.666914,
            properties: {},
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201211T134211_20201211T134207_T21JZN',
            date: '2020-12-11',
            cloud_cover: 3.394707,
            properties: {
              NDVI_min: 0.6258776328986961,
              NDVI_mean: 0.8644139282993847,
              NDVI_max: 0.9083712312615799,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20201226T134209_20201226T134208_T21JZN',
            date: '2020-12-26',
            cloud_cover: 5.538495,
            properties: {
              NDVI_min: 0.8577470203767782,
              NDVI_mean: 0.9400840400978111,
              NDVI_max: 0.9484598963098506,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210219T134211_20210219T134210_T22JBT',
            date: '2021-02-19',
            cloud_cover: 0.048026,
            properties: {
              NDVI_min: 0.6198305929247633,
              NDVI_mean: 0.8493648507147807,
              NDVI_max: 0.9234194122885129,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210311T134211_20210311T134210_T22JBT',
            date: '2021-03-11',
            cloud_cover: 0.027823,
            properties: {
              NDVI_min: 0.18859739723197685,
              NDVI_mean: 0.21288812680181662,
              NDVI_max: 0.7391304347826086,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210316T134209_20210316T134210_T22JBT',
            date: '2021-03-16',
            cloud_cover: 1.514411,
            properties: {
              NDVI_min: 0.1532783326096396,
              NDVI_mean: 0.17811568530023103,
              NDVI_max: 0.759515570934256,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210331T134211_20210331T134208_T22JBT',
            date: '2021-03-31',
            cloud_cover: 0.182435,
            properties: {
              NDVI_min: 0.18848758465011287,
              NDVI_mean: 0.3065163143610897,
              NDVI_max: 0.8970996216897856,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210405T134209_20210405T134208_T21JZN',
            date: '2021-04-05',
            cloud_cover: 16.068709,
            properties: {},
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210410T134211_20210410T134206_T22JBT',
            date: '2021-04-10',
            cloud_cover: 5.631998,
            properties: {
              NDVI_min: 0.18242894056847545,
              NDVI_mean: 0.3004392830467052,
              NDVI_max: 0.8414802540734604,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210415T134209_20210415T134206_T22JBT',
            date: '2021-04-15',
            cloud_cover: 0.434793,
            properties: {
              NDVI_min: 0.1810939989378651,
              NDVI_mean: 0.333693715868297,
              NDVI_max: 0.8078880407124682,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210420T134211_20210420T134351_T22JBT',
            date: '2021-04-20',
            cloud_cover: 0.031994,
            properties: {
              NDVI_min: 0.19859608072535828,
              NDVI_mean: 0.3843570179317669,
              NDVI_max: 0.856,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210430T134211_20210430T134208_T22JBT',
            date: '2021-04-30',
            cloud_cover: 0.073109,
            properties: {
              NDVI_min: 0.20663046584738498,
              NDVI_mean: 0.4320999183154177,
              NDVI_max: 0.870677537250492,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210510T134211_20210510T134210_T22JBT',
            date: '2021-05-10',
            cloud_cover: 0.031277,
            properties: {
              NDVI_min: 0.18697225572979492,
              NDVI_mean: 0.42804254864090785,
              NDVI_max: 0.8517722878625135,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210520T134211_20210520T134442_T22JBT',
            date: '2021-05-20',
            cloud_cover: 7.087797,
            properties: {
              NDVI_min: 0.19174670935610102,
              NDVI_mean: 0.44667427970795504,
              NDVI_max: 0.8125523012552301,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210525T134209_20210525T134211_T22JBT',
            date: '2021-05-25',
            cloud_cover: 2.388244,
            properties: {
              NDVI_min: 0.2234910277324633,
              NDVI_mean: 0.4791520247628466,
              NDVI_max: 0.8364618153767035,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210604T134209_20210604T134211_T21JZN',
            date: '2021-06-04',
            cloud_cover: 0.023809,
            properties: {
              NDVI_min: 0.2393617021276596,
              NDVI_mean: 0.410036317335762,
              NDVI_max: 0.8454043520623579,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210614T134209_20210614T134211_T22JBT',
            date: '2021-06-14',
            cloud_cover: 0.02567,
            properties: {
              NDVI_min: 0.20588235294117646,
              NDVI_mean: 0.46400321264157823,
              NDVI_max: 0.854586129753915,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210629T134211_20210629T134212_T21JZN',
            date: '2021-06-29',
            cloud_cover: 5.401025,
            properties: {},
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210709T134211_20210709T134213_T22JBT',
            date: '2021-07-09',
            cloud_cover: 0.02934,
            properties: {
              NDVI_min: 0.3128665056916178,
              NDVI_mean: 0.6014419170483698,
              NDVI_max: 0.905680224403927,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210719T134211_20210719T134445_T21JZN',
            date: '2021-07-19',
            cloud_cover: 1.77011,
            properties: {
              NDVI_min: 0.4660776270116756,
              NDVI_mean: 0.7184014307730007,
              NDVI_max: 0.9219080592421619,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210724T134209_20210724T134212_T21JZN',
            date: '2021-07-24',
            cloud_cover: 9.916168,
            properties: {
              NDVI_min: 0.4716143355534412,
              NDVI_mean: 0.6791714912726435,
              NDVI_max: 0.8693862945070675,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210729T134211_20210729T134214_T21JZN',
            date: '2021-07-29',
            cloud_cover: 0.642257,
            properties: {
              NDVI_min: 0.49660326086956524,
              NDVI_mean: 0.7347436023948157,
              NDVI_max: 0.9064263608228654,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210803T134209_20210803T134212_T22JBT',
            date: '2021-08-03',
            cloud_cover: 1.084469,
            properties: {
              NDVI_min: 0.4560777957860616,
              NDVI_mean: 0.7355939807275332,
              NDVI_max: 0.9035878564857406,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210808T134211_20210808T134213_T22JBT',
            date: '2021-08-08',
            cloud_cover: 0.025949,
            properties: {
              NDVI_min: 0.44430538172715894,
              NDVI_mean: 0.7373624017151958,
              NDVI_max: 0.8922405615632707,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210818T134211_20210818T134358_T22JBT',
            date: '2021-08-18',
            cloud_cover: 0.020255,
            properties: {
              NDVI_min: 0.4891518737672584,
              NDVI_mean: 0.74026474976951,
              NDVI_max: 0.8494623655913979,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210902T134019_20210902T134807_T21JZN',
            date: '2021-09-02',
            cloud_cover: 14.019741,
            properties: {
              NDVI_min: 0.5441262419637639,
              NDVI_mean: 0.7891764835810617,
              NDVI_max: 0.8947786380782211,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210907T134031_20210907T134829_T21JZN',
            date: '2021-09-07',
            cloud_cover: 4.762658,
            properties: {
              NDVI_min: 0.43507837917775805,
              NDVI_mean: 0.7016430065314057,
              NDVI_max: 0.8406323435163426,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210922T134029_20210922T134631_T22JBT',
            date: '2021-09-22',
            cloud_cover: 6.876981,
            properties: {
              NDVI_min: 0.22766217870257038,
              NDVI_mean: 0.5952763365203632,
              NDVI_max: 0.7639902676399026,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20210927T134031_20210927T134030_T22JBT',
            date: '2021-09-27',
            cloud_cover: 0.018225,
            properties: {
              NDVI_min: 0.21852060982495766,
              NDVI_mean: 0.46754490448514996,
              NDVI_max: 0.6204737271984754,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20211022T134209_20211022T134212_T21JZN',
            date: '2021-10-22',
            cloud_cover: 0.047047,
            properties: {
              NDVI_min: 0.1282051282051282,
              NDVI_mean: 0.1749918939228147,
              NDVI_max: 0.37212643678160917,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20211101T134209_20211101T134211_T21JZN',
            date: '2021-11-01',
            cloud_cover: 1.484766,
            properties: {
              NDVI_min: 0.09327217125382263,
              NDVI_mean: 0.1584530586717427,
              NDVI_max: 0.47906566769501985,
            },
            urls: {},
          },
          {
            id: 'COPERNICUS/S2_SR_HARMONIZED/20211106T134211_20211106T134214_T21JZN',
            date: '2021-11-06',
            cloud_cover: 16.852513,
            properties: {
              NDVI_min: 0.1401059220028888,
              NDVI_mean: 0.17544969295454088,
              NDVI_max: 0.46441211167911917,
            },
            urls: {},
          },
        ],
      },
      monitoringDates: {
        end_date: '2021-11-18T03:00:00.000Z',
        start_date: '2020-06-07T03:00:00.000Z',
      },
    },
  ],
  data: [
    {
      line: {
        color: 'grey',
        shape: 'spline',
        width: 1,
      },
      mode: 'lines',
      name: 'Indice Vegetativo',
      showlegend: true,
      x: [
        '2020-06-24',
        '2020-07-04',
        '2020-07-09',
        '2020-07-14',
        '2020-07-19',
        '2020-07-24',
        '2020-08-03',
        '2020-08-08',
        '2020-08-23',
        '2020-08-28',
        '2020-09-07',
        '2020-09-12',
        '2020-09-22',
        '2020-10-02',
        '2020-10-07',
        '2020-10-12',
        '2020-10-17',
        '2020-10-22',
        '2020-10-27',
        '2020-11-01',
        '2020-11-06',
        '2020-12-11',
        '2020-12-26',
        '2021-02-19',
        '2021-03-11',
        '2021-03-16',
        '2021-03-31',
        '2021-04-10',
        '2021-04-15',
        '2021-04-20',
        '2021-04-30',
        '2021-05-10',
        '2021-05-20',
        '2021-05-25',
        '2021-06-04',
        '2021-06-14',
        '2021-07-09',
        '2021-07-19',
        '2021-07-24',
        '2021-07-29',
        '2021-08-03',
        '2021-08-08',
        '2021-08-18',
        '2021-09-02',
        '2021-09-07',
        '2021-09-22',
        '2021-09-27',
        '2021-10-22',
        '2021-11-01',
        '2021-11-06',
      ],
      y: [
        0.7710650829511565, 0.8347968696571877, 0.8789012528605571,
        0.9033782325612645, 0.90822780875931, 0.8974598566582384,
        0.8378585550854012, 0.7484936551593193, 0.6269203892970819,
        0.4869789298758033, 0.356620145453581, 0.2519640370471329,
        0.18765590934243598, 0.15551253780019028, 0.16808742051021985,
        0.16002479633807679, 0.12522729836482943, 0.1578463720351309,
        0.2664810409928742, 0.46724325602965566, 0.6030845407188999,
        0.6531272936837929, 0.6459681437856772, 0.5850531424764086,
        0.4609682735275597, 0.29999740054329144, 0.24246035846343375,
        0.2672589072792833, 0.36277784656383705, 0.40734712950232255,
        0.4204527488423163, 0.42862950541457256, 0.4332113694576577,
        0.46171603327205674, 0.4983618759060632, 0.5528749036150847,
        0.610656982189191, 0.6671154676749131, 0.7157895024013189,
        0.7468834800110948, 0.7584575451926524, 0.7604376181725656,
        0.7679548272938405, 0.7083318171388279, 0.6093008139264231,
        0.5674567493561853, 0.46890949966325807, 0.35055008923372694,
        0.2123785180675919, 0.05439478616485294,
      ],
      type: 'scatter',
    },
    {
      line: {
        color: 'blue',
        shape: 'spline',
        width: 4,
      },
      mode: 'lines',
      name: 'Data indicada de plantio no projeto: (07/06 10/06)',
      showlegend: true,
      x: [
        '2020-06-24',
        '2020-07-04',
        '2020-07-09',
        '2020-07-14',
        '2020-07-19',
        '2020-07-24',
        '2020-08-03',
        '2020-08-08',
        '2020-08-23',
        '2020-08-28',
        '2020-09-07',
        '2020-09-12',
        '2020-09-22',
        '2020-10-02',
        '2020-10-07',
        '2020-10-12',
        '2020-10-17',
        '2020-10-22',
        '2020-10-27',
        '2020-11-01',
        '2020-11-06',
        '2020-12-11',
        '2020-12-26',
        '2021-02-19',
        '2021-03-11',
        '2021-03-16',
        '2021-03-31',
        '2021-04-10',
        '2021-04-15',
        '2021-04-20',
        '2021-04-30',
        '2021-05-10',
        '2021-05-20',
        '2021-05-25',
        '2021-06-04',
        '2021-06-14',
        '2021-07-09',
        '2021-07-19',
        '2021-07-24',
        '2021-07-29',
        '2021-08-03',
        '2021-08-08',
        '2021-08-18',
        '2021-09-02',
        '2021-09-07',
        '2021-09-22',
        '2021-09-27',
        '2021-10-22',
        '2021-11-01',
        '2021-11-06',
      ],
      y: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      type: 'scatter',
    },
    {
      line: {
        color: 'orange',
        shape: 'spline',
        width: 4,
      },
      mode: 'markers',
      name: 'Data indicada de colheita no projeto: (15/10 18/10)',
      showlegend: true,
      x: [
        '2020-06-24',
        '2020-07-04',
        '2020-07-09',
        '2020-07-14',
        '2020-07-19',
        '2020-07-24',
        '2020-08-03',
        '2020-08-08',
        '2020-08-23',
        '2020-08-28',
        '2020-09-07',
        '2020-09-12',
        '2020-09-22',
        '2020-10-02',
        '2020-10-07',
        '2020-10-12',
        '2020-10-17',
        '2020-10-22',
        '2020-10-27',
        '2020-11-01',
        '2020-11-06',
        '2020-12-11',
        '2020-12-26',
        '2021-02-19',
        '2021-03-11',
        '2021-03-16',
        '2021-03-31',
        '2021-04-10',
        '2021-04-15',
        '2021-04-20',
        '2021-04-30',
        '2021-05-10',
        '2021-05-20',
        '2021-05-25',
        '2021-06-04',
        '2021-06-14',
        '2021-07-09',
        '2021-07-19',
        '2021-07-24',
        '2021-07-29',
        '2021-08-03',
        '2021-08-08',
        '2021-08-18',
        '2021-09-02',
        '2021-09-07',
        '2021-09-22',
        '2021-09-27',
        '2021-10-22',
        '2021-11-01',
        '2021-11-06',
      ],
      y: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      type: 'scatter',
    },
    {
      line: {
        color: 'aqua',
        shape: 'spline',
        width: 4,
      },
      marker: {
        color: 'aqua',
        line: {
          color: 'gray',
          width: 1,
        },
        size: 10,
      },
      mode: 'markers',
      name: 'Plantio realizado (24/07/2021)',
      showlegend: true,
      x: ['2021-07-24'],
      y: [0.7157894968986511],
      type: 'scatter',
    },
    {
      line: {
        color: 'green',
        shape: 'spline',
        width: 4,
      },
      marker: {
        color: 'green',
        line: {
          color: 'gray',
          width: 1,
        },
        size: 10,
      },
      mode: 'markers',
      name: 'Pico vegetativo (18/08/2021)',
      showlegend: true,
      x: ['2021-08-18'],
      y: [0.7679548263549805],
      type: 'scatter',
    },
    {
      line: {
        color: 'yellow',
        shape: 'spline',
        width: 4,
      },
      marker: {
        color: 'yellow',
        line: {
          color: 'gray',
          width: 1,
        },
        size: 10,
      },
      mode: 'markers',
      name: 'Inicio de senescência (27/09/2021)',
      showlegend: true,
      x: ['2021-09-27'],
      y: [0.46890950202941895],
      type: 'scatter',
    },
    {
      line: {
        color: 'red',
        shape: 'spline',
        width: 4,
      },
      marker: {
        color: 'red',
        line: {
          color: 'gray',
          width: 1,
        },
        size: 10,
      },
      mode: 'markers',
      name: 'Colhido (06/11/2021)',
      showlegend: true,
      x: ['2021-11-06'],
      y: [0.054394785314798355],
      type: 'scatter',
    },
  ],
};
