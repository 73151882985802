import { useEffect, useState } from 'react';
import styles from './Panel.module.css';
import spotViewLogo from '../../../assets/spotViewLogo.svg';
import Icon from '../../../components/Icon/Icon.component';
import ButtonTab from '../components/ButtonTab/ButtonTab.component';
import { ChartTypes, Operation } from '../Polygons.page';
import { useNavigate } from 'react-router-dom';

const dataPolygons = {
  operationId: 12342,
  owner: 'João da Silva',
  area: '57.6 ha',
  culture: 'Soja',
  soilType: 'Argiloso',
  zoning: '??',
};

const dataWeather = {
  max: 27.3,
  min: 20.2,
  median: 23.4,
};

const dataNdvi = [
  {
    date: 'Fev 19, 2024',
    cloud: 100,
    image: 90,
  },
  {
    date: 'Fev 20, 2024',
    cloud: 100,
    image: 91,
  },
  {
    date: 'Fev 21, 2024',
    cloud: 92,
    image: 82,
  },
  {
    date: 'Fev 22, 2024',
    cloud: 93,
    image: 93,
  },
  {
    date: 'Fev 23, 2024',
    cloud: 94,
    image: 100,
  },
  {
    date: 'Fev 24, 2024',
    cloud: 95,
    image: 101,
  },
  {
    date: 'Fev 25, 2024',
    cloud: 96,
    image: 102,
  },
  {
    date: 'Fev 26, 2024',
    cloud: 97,
    image: 103,
  },
];

interface PanelProps {
  selectedCharts: ChartTypes[];
  setSelectedCharts: (value: ChartTypes[]) => void;
  setShowChart: (chart: ChartTypes) => void;
  selectedFeature: any;
  setSelectedFeature: (value: any) => void;
  selectedOperation: Operation | null;
  setSelectedOperation: (value: Operation | null) => void;
  operations: Operation[];
}

function Panel({
  selectedCharts,
  setSelectedCharts,
  setShowChart,
  selectedFeature,
  setSelectedFeature,
  selectedOperation,
  setSelectedOperation,
  operations,
}: PanelProps): JSX.Element {
  const [isVisible, setIsVisible] = useState({
    polygons: false,
    weather: false,
    ndvi: false,
  });

  const navigate = useNavigate();

  useEffect(() => {}, [isVisible]);

  const [panelOpen, setPanelOpen] = useState(true);

  const [searchFocus, setSearchFocus] = useState(false);

  const [searchOperation, setSearchOperation] = useState('');

  return (
    <>
      {' '}
      {panelOpen ? (
        <div className={styles.panelContainer}>
          <div className={styles.panelHeader}>
            <div
              className={styles.headerTitle}
              onClick={() => setPanelOpen(false)}
            >
              <span className={styles.buttonBack} onClick={() => navigate(-1)}>
                <Icon name="ArrowLeft" />
                <img src={spotViewLogo} alt="" width={80} height={40} />
              </span>
              <span className={styles.buttonHandleVisibility}>
                <Icon name="CarretDoubleLeft" />
              </span>
            </div>
            <div className={styles.boxDataList}>
              <input
                type="text"
                className={styles.inputOperation}
                placeholder="Pesquisar Operação"
                value={searchOperation}
                onChange={(e) => setSearchOperation(e.target.value)}
                list="operation"
                onClick={() => setSearchFocus(true)}
              />
              {selectedOperation && (
                <div
                  className={styles.clearSelectedOperation}
                  onClick={() => {
                    setSelectedOperation(null);
                    setSearchOperation('');
                    setSelectedFeature(null);
                  }}
                >
                  X
                </div>
              )}

              {searchFocus && (
                <div
                  className={styles.customDataList}
                  onMouseLeave={() => setSearchFocus(false)}
                >
                  {operations
                    .filter((operation) => {
                      const operationId = operation.operationId.toString();
                      return operationId.includes(searchOperation);
                    })
                    .map((operation) => (
                      <span
                        onClick={() => {
                          setSelectedOperation(operation);
                          setSearchOperation(operation.operationId.toString());
                          setSearchFocus(false);
                        }}
                      >
                        {operation.operationId}
                      </span>
                    ))}
                </div>
              )}
            </div>
            {selectedFeature ? (
              <>
                <div className={styles.showGraphBlock}>
                  <div className={styles.legend}>Exibir Gráfico</div>
                  <ButtonTab
                    iconName={
                      selectedCharts.includes('ndvi') ? 'RadarHover' : 'Radar'
                    }
                    label="NDVI"
                    onClick={() => {
                      if (selectedCharts.includes('ndvi')) {
                        setSelectedCharts(
                          selectedCharts.filter((chart) => chart !== 'ndvi')
                        );
                      } else {
                        setSelectedCharts([...selectedCharts, 'ndvi']);
                        setShowChart('ndvi');
                      }
                    }}
                    active={selectedCharts.includes('ndvi')}
                  />
                  <ButtonTab
                    iconName={
                      selectedCharts.includes('weather')
                        ? 'ClimateHover'
                        : 'Climate'
                    }
                    label="Clima"
                    onClick={() => {
                      if (selectedCharts.includes('weather')) {
                        setSelectedCharts(
                          selectedCharts.filter((chart) => chart !== 'weather')
                        );
                      } else {
                        setSelectedCharts([...selectedCharts, 'weather']);
                        setShowChart('weather');
                      }
                    }}
                    active={selectedCharts.includes('weather')}
                  />
                </div>

                <div className={styles.filterDate}>
                  <div className={styles.filterDateItem}>
                    <p className={styles.dateLegend}>De:</p>
                    <input type="date" className={styles.inputDate} />
                  </div>
                  <div className={styles.filterDateItem}>
                    <p className={styles.dateLegend}>Até:</p>
                    <input type="date" className={styles.inputDate} />
                  </div>
                </div>
              </>
            ) : (
              <>
                {selectedOperation && (
                  <p className={styles.selectPolygonMessage}>
                    Selecione um polígono
                  </p>
                )}
              </>
            )}
          </div>
          {selectedFeature && (
            <div className={styles.panelContent}>
              <div
                className={styles.contentItemHeader}
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    polygons: !isVisible.polygons,
                  })
                }
              >
                <div className={styles.contentItemTitle}>
                  <Icon name="MapNotFilled" size={16} />
                  <span>Dados do polígono</span>
                </div>
                <Icon
                  name={isVisible.polygons ? 'CarretUp' : 'CarretDown'}
                  size={12}
                />
              </div>
              {isVisible.polygons && (
                <>
                  <div className={styles.contentItemData}>
                    <span>Operação</span>
                    <span>{dataPolygons.operationId}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Proprietário</span>
                    <span>{dataPolygons.owner}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Área</span>
                    <span>{dataPolygons.area}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Cultura</span>
                    <span>{dataPolygons.culture}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Tipo de Solo</span>
                    <span>{dataPolygons.soilType}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Zoneamento</span>
                    <span>{dataPolygons.zoning}</span>
                  </div>
                </>
              )}
              <div
                className={styles.contentItemHeader}
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    weather: !isVisible.weather,
                  })
                }
              >
                <div className={styles.contentItemTitle}>
                  <Icon name="Climate" size={16} />
                  <span>Clima</span>
                </div>
                <Icon
                  name={isVisible.weather ? 'CarretUp' : 'CarretDown'}
                  size={12}
                />
              </div>
              {isVisible.weather && (
                <>
                  <div className={styles.contentItemData}>
                    <span>Temperatura Máxima</span>
                    <span>{dataWeather.max}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Temperatura Mínima</span>
                    <span>{dataWeather.min}</span>
                  </div>
                  <div className={styles.contentItemData}>
                    <span>Temperatura Média</span>
                    <span>{dataWeather.median}</span>
                  </div>
                </>
              )}
              <div
                className={styles.contentItemHeader}
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    ndvi: !isVisible.ndvi,
                  })
                }
              >
                <div className={styles.contentItemTitle}>
                  <Icon name="Radar" size={16} />
                  <span>NDVI</span>
                </div>
                <Icon
                  name={isVisible.ndvi ? 'CarretUp' : 'CarretDown'}
                  size={12}
                />
              </div>
              {isVisible.ndvi && (
                <>
                  {dataNdvi.map((item, index) => (
                    <div className={styles.contentItemNdvi}>
                      <span>{item.date}</span>
                      <span>
                        <Icon name="Cloud" size={12} />
                        {item.cloud}
                      </span>
                      <span>
                        <Icon name="Image" size={12} />
                        {item.image}
                      </span>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          className={styles.panelCollapsed}
          onClick={() => setPanelOpen(true)}
        >
          <span className={styles.buttonBack} onClick={() => navigate(-1)}>
            <Icon name="ArrowLeft" />
            <img src={spotViewLogo} alt="" width={80} height={40} />
          </span>
          <span className={styles.buttonHandleVisibility}>
            <Icon name="CarretDoubleRight" />
          </span>
        </div>
      )}
    </>
  );
}

export default Panel;
