import { useEffect, useState } from 'react';
import PolygonMap from './PolygonMap/PolygonMap.component';
import styles from './Polygons.module.css';
import Panel from './Panel/Panel.component';
import ChartContainer from './ChartContainer/ChartContainer.component';
import { dataPolygon, dataPolygon2 } from './data/data';
import { LatLngExpression } from 'leaflet';

export type ChartTypes = 'ndvi' | 'weather';

export interface Operation {
  operationId: number;
  centroid: LatLngExpression;
  dataPolygon: any;
}

function Polygons(): JSX.Element {
  const [geoFilter, setGeoFilter] = useState(null);

  const [selectedCharts, setSelectedCharts] = useState<ChartTypes[]>([]);

  const [showChart, setShowChart] = useState<ChartTypes>();

  useEffect(() => {
    if (selectedCharts.length === 1) {
      setShowChart(selectedCharts[0]);
    }
  }, [selectedCharts]);

  const [operations, setOperations] = useState<Operation[]>([]);

  const getOperations = async () => {
    //Logica de requisição de operações
    const operations: Operation[] = [
      {
        operationId: 1232,
        centroid: [-23.15739914334851, -45.820448444570786],
        dataPolygon: dataPolygon,
      },
      {
        operationId: 2333,
        centroid: [-24.84527362646034, -49.56797761690498],
        dataPolygon: dataPolygon2,
      },
    ];

    setOperations(operations);
  };

  useEffect(() => {
    getOperations();
  }, []);

  const [selectedOperation, setSelectedOperation] = useState<Operation | null>(
    null
  );

  const [selectedOperationPolygons, setOperationPolygons] = useState<any>();

  const getDataOperationPolygons = async (operationId: number) => {
    //Logica de requisição de poligonos
    setOperationPolygons(selectedOperation?.dataPolygon);
  };

  useEffect(() => {
    if (selectedOperation) {
      getDataOperationPolygons(selectedOperation.operationId);
    }
  }, [selectedOperation]);

  return (
    <div className={styles.page}>
      <main className={styles.main}>
        <PolygonMap
          key={JSON.stringify(selectedOperationPolygons)}
          geoFilter={geoFilter}
          setGeoFilter={setGeoFilter}
          dataPolygon={selectedOperationPolygons}
          selectedOperation={selectedOperation}
        />

        <div className={styles.toolsContainer}>
          <>
            <Panel
              selectedCharts={selectedCharts}
              setSelectedCharts={setSelectedCharts}
              setShowChart={setShowChart}
              selectedFeature={geoFilter}
              setSelectedFeature={setGeoFilter}
              selectedOperation={selectedOperation}
              setSelectedOperation={setSelectedOperation}
              operations={operations}
            />
            {geoFilter && (
              <ChartContainer
                selectedFeature={geoFilter}
                showChart={showChart}
                setShowChart={setShowChart}
                selectedCharts={selectedCharts}
                setSelectedCharts={setSelectedCharts}
              />
            )}
          </>
        </div>
      </main>
    </div>
  );
}

export default Polygons;
