import React from 'react';
import ChartNDVI from '../components/ChartNDVI.component';
import { dataNDVI } from '../data/dataNDVI';
import styles from './ChartContainer.module.css';
import ChartWeather from '../components/ChartWeather.component';
import { ChartTypes } from '../Polygons.page';
import { subtitleChartEnum, titleChartEnum } from '../data/chartEnum';
import Icon from '../../../components/Icon/Icon.component';

interface ChartProps {
  selectedFeature?: {
    daily: {
      time: string[];
      weathercode: number[];
      temperature_2m_mean: number[];
      precipitation_sum: number[];
    };
  };
  showChart: ChartTypes | undefined;
  setShowChart: (chart: ChartTypes) => void;
  selectedCharts: ChartTypes[];
  setSelectedCharts: (chart: ChartTypes[]) => void;
}

function ChartContainer({
  selectedFeature,
  selectedCharts,
  setSelectedCharts,
  showChart,
  setShowChart,
}: ChartProps): JSX.Element {
  const charts = {
    ndvi: <ChartNDVI dataNDVI={dataNDVI} />,
    weather: selectedFeature ? (
      <ChartWeather daily={selectedFeature.daily} />
    ) : null,
  };

  return (
    <>
      {selectedCharts.length > 0 && selectedFeature && showChart && (
        <div className={styles.main}>
          <div
            className={styles.close}
            onClick={() =>
              setSelectedCharts(
                selectedCharts.filter((chart) => chart !== showChart)
              )
            }
          >
            <Icon name="Close" size={16} />{' '}
          </div>
          <div className={styles.chartDetails}>
            {selectedCharts.map((chart, index) => (
              <div
                className={styles.chartTitle}
                onClick={() => setShowChart(selectedCharts[index])}
                style={{
                  backgroundColor:
                    showChart === selectedCharts[index]
                      ? '#27293d'
                      : '#27293d95',
                  color:
                    showChart === selectedCharts[index] ? '#e2e2e2' : '#e2e2e2',
                }}
              >
                {titleChartEnum[selectedCharts[index]]}
              </div>
            ))}
            <div className={styles.subtitles}>
              {subtitleChartEnum[showChart].map((subtitle, index) => (
                <span key={index} className={styles.subtitle}>
                  <span>{subtitle.icon}</span>
                  <p>{subtitle.name}</p>
                </span>
              ))}
            </div>
          </div>

          <div className={styles.chart}>{charts[showChart]}</div>
        </div>
      )}
    </>
  );
}

export default ChartContainer;
