// import "leaflet/dist/leaflet.css";
import { useEffect, useState } from 'react';
import AreaPolygon from '../layers/AreaPolygon';
import styles from './PolygonMap.module.css';
import { MapContainer, TileLayer, useMap, ZoomControl } from 'react-leaflet';
import { Operation } from '../Polygons.page';
import { LatLngExpression } from 'leaflet';

interface PolygonMapProps {
  geoFilter: any;
  setGeoFilter: any;
  dataPolygon: any;
  selectedOperation: Operation | null;
}

function PolygonMap({
  geoFilter,
  setGeoFilter,
  dataPolygon,
  selectedOperation,
}: PolygonMapProps): JSX.Element {
  const getGeoFilter = () => geoFilter;

  const defaultCenter: LatLngExpression = [
    -15.15739914334851, -45.820448444570786,
  ];

  const [center, setCenter] = useState<LatLngExpression>(
    selectedOperation?.centroid || defaultCenter
  );

  function SetViewOnCoords({ coords }: { coords: LatLngExpression }) {
    const map = useMap();

    useEffect(() => {
      if (coords && selectedOperation) {
        map.setView(coords, 13);
      }
    }, [map, coords]);

    return null;
  }

  return (
    <>
      <MapContainer
        center={center}
        zoom={5}
        scrollWheelZoom={true}
        className={styles.leafletContainer}
        zoomControl={false}
      >
        <ZoomControl position="topright" />
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        {selectedOperation && (
          <AreaPolygon
            data={dataPolygon}
            getGeoFilter={getGeoFilter}
            setGeoFilter={setGeoFilter}
          />
        )}

        <SetViewOnCoords coords={center} />
      </MapContainer>
    </>
  );
}

export default PolygonMap;
