import React from 'react';
import Plot from 'react-plotly.js';
import LineType from '../types/chartNDVI/LineType';
import MarkerType from '../types/chartNDVI/MarkerLine';
import PlotDataType from '../types/chartNDVI/PlotDataType';
import { useEffect, useState } from 'react';
import { Data } from 'plotly.js';
import GlebaType from '../types/chartNDVI/GlebaType';
import styles from './ChartNDVIComponent.module.css';

function ChartNDVI({ dataNDVI }: any) {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [data, setData] = useState<Data[]>([]);

  const MARKER_SIZE = 13;
  const MARKER_BORDER_WIDTH = 3;

  const colorMap: Record<string, Partial<LineType & { marker: MarkerType }>> = {
    grey: { color: '#00940F', width: 1 },
    blue: { marker: { color: '#B3E0FF50' } },
    orange: { marker: { color: '#FFD07850' } },
    aqua: {
      color: '#FFFFFF',
      marker: {
        size: MARKER_SIZE,
        line: { color: '#E21AAA', width: MARKER_BORDER_WIDTH },
      },
    },
    green: {
      color: '#FFFFFF',
      marker: {
        size: MARKER_SIZE,
        line: { color: '#00FF00', width: MARKER_BORDER_WIDTH },
      },
    },
    yellow: {
      color: '#FFFFFF',
      marker: {
        size: MARKER_SIZE,
        line: { color: '#DADA00', width: MARKER_BORDER_WIDTH },
      },
    },
    red: {
      color: '#FFFFFF',
      marker: {
        size: MARKER_SIZE,
        line: { color: '#FF0000', width: MARKER_BORDER_WIDTH },
      },
    },
  };

  /**
   * Replaces the configuration of plot data with new values based on the @var {colorMap}.
   * ! Will be modified later
   *
   * @param {PlotDataType[]} data - An array of plot data to be modified.
   * @return {PlotDataType[]} - The modified plot data array.
   */
  const replaceConfig = (data: PlotDataType[]): PlotDataType[] => {
    const newConfig = data.map((datum: PlotDataType) => {
      if (!datum.line.color) return datum;

      const replacement: Partial<LineType & { marker: MarkerType }> =
        colorMap[datum.line.color];

      if (!replacement.marker) {
        const updatedLine = { ...datum.line, ...replacement };
        if (datum.line.color === 'grey') {
          return {
            ...datum,
            fill: 'tozeroy',
            fillcolor: '#9BFFEF15',
            line: updatedLine,
          };
        }
        return { ...datum, line: updatedLine };
      } else {
        if (datum.line.color === 'blue') {
          return {
            ...datum,
            mode: 'lines',
            opacity: 0.2,
            x: [
              // Na verificação do dataNDVI.planting_start,
              // no back end ele está vindo de operationsData.planting_start e .planting_end
              dataNDVI.planting_start,
              new Date(dataNDVI.planting_end).getTime() ===
              new Date(dataNDVI.planting_start).getTime()
                ? new Date(
                    new Date(dataNDVI.planting_end).setDate(
                      new Date(dataNDVI.planting_end).getDate() + 1
                    )
                  )
                    .toISOString()
                    .split('T')[0]
                : dataNDVI.planting_end,
            ],
            y: [1.2, 1.2],
            fill: 'tozeroy',
            fillcolor: '#0E9CFF30',
            line: replacement.line,
          };
        }

        if (datum.line.color === 'orange') {
          return {
            ...datum,
            mode: 'lines',
            opacity: 0.2,
            x: [
              // Na verificação do dataNDVI.planting_start,
              // no back end ele está vindo de operationsData.crop_start e .crop_end
              dataNDVI.crop_start,
              new Date(dataNDVI.crop_end).getTime() ===
              new Date(dataNDVI.crop_start).getTime()
                ? new Date(
                    new Date(dataNDVI.crop_end).setDate(
                      new Date(dataNDVI.crop_end).getDate() + 1
                    )
                  )
                    .toISOString()
                    .split('T')[0]
                : dataNDVI.crop_end,
            ],
            y: [1.2, 1.2],
            fill: 'tozeroy',
            fillcolor: '#FFA50030',
            line: replacement.line,
          };
        }

        return {
          ...datum,
          line: { ...datum.line, ...replacement },
          marker: { ...datum.marker, ...replacement.marker },
        };
      }
    });

    return newConfig;
  };

  // Coloquei o valor da glebaId aqui para a verificação da variavel currentGleba abaixo,
  // Depois de conectado com o backend, pegar esse valor e atribui a glebaId
  const glebaId = dataNDVI.glebes.length ? dataNDVI.glebes[0].id : null;

  useEffect(() => {
    const fetchData = () => {
      // Na verificação do dataNDVI.glebes, no back end ele está vindo de operationsData.glebes
      const currentGleba: GlebaType | undefined | null = Array.isArray(
        dataNDVI.glebes
      )
        ? dataNDVI.glebes.find((gleba: any) => gleba.id === glebaId)
        : null;

      if (!currentGleba) return;

      const formattedData: PlotDataType[] = currentGleba.chart_ndvi
        ? currentGleba.chart_ndvi.data.map((datum: PlotDataType) => ({
            line: datum.line,
            mode: datum.mode,
            name: datum.name,
            showlegend: datum.showlegend,
            x: datum.x,
            y: datum.y,
            type: datum.type,
          }))
        : [];

      const updatedData: PlotDataType[] = replaceConfig(formattedData);
      setData(updatedData);
    };

    fetchData();
  }, [glebaId]);

  useEffect(() => {
    let tries = 0;
    const MAX_TRIES = 3;
    const loadingInterval = setInterval(() => {
      tries++;

      if (data.length > 0) {
        clearInterval(loadingInterval);
        return;
      }

      if (tries >= MAX_TRIES) {
        clearInterval(loadingInterval);
      }
    }, 1000);

    return () => {
      clearInterval(loadingInterval);
    };
  }, [data]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
      }}
    >
      <Plot
        className={styles.componentPlot}
        key={dataNDVI.glebes.length}
        data={data}
        layout={{
          height: isMobile ? 100 : 200,
          yaxis: {
            autorange: false,
            range: [0, 1],
            showgrid: false,
            linewidth: 1,
          },
          xaxis: { showgrid: false, automargin: true },
          hovermode: 'x unified',
          hoverlabel: {
            bordercolor: '#e7e7e7',
            namelength: isMobile ? 30 : 55,
            font: { color: 'white', size: isMobile ? 8 : 12, family: 'Arial' },
            bgcolor: '#27293D',
          },
          showlegend: false,
          legend: {
            bgcolor: '#F4F4F4',
            bordercolor: '#000',
            orientation: 'h',
          },
          font: {
            family: '"Raleway",sans-serif',
            size: isMobile ? 12 : 14,
            color: '#e7e7e7',
          },
          margin: {
            l: isMobile ? 40 : 50,
            r: isMobile ? 20 : 50,
            t: isMobile ? 20 : 50,
            b: isMobile ? 20 : 50,
            pad: 4,
          },
          plot_bgcolor: 'transparent',
          paper_bgcolor: 'transparent',
        }}
        config={{
          locales: 'pt-BR',
          locale: 'pt-BR',
          responsive: true,
          modeBarButtonsToRemove: ['toImage'],
          displayModeBar: false,
        }}
        revision={0}
      />
    </div>
  );
}

export default ChartNDVI;
