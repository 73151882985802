import { GeoJSON } from 'react-leaflet';
import { FeatureCollection, Polygon } from 'geojson';

function AreaPolygon({ data, getGeoFilter, setGeoFilter }: any): JSX.Element {
  const geoFilter = getGeoFilter();

  return (
    <GeoJSON
      key="geo-json-areas-SJC"
      data={data as unknown as FeatureCollection<Polygon>}
      eventHandlers={{
        click: (e) => {
          setGeoFilter((prevState: any) => {
            const same = prevState === e.propagatedFrom.feature;
            return same ? null : e.propagatedFrom.feature;
          });
        },
      }}
      style={(feature) => {
        return {
          color: geoFilter === feature ? '#3388FF' : '#FFFFFF',
          weight: 3,
        };
      }}
    ></GeoJSON>
  );
}

export default AreaPolygon;
